import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g viewBox="0 0 980.000000 980.000000" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

        <path d="M5827 7851 c-32 -4 -61 -11 -65 -16 -4 -6 -25 -11 -47 -11 -22 -1
-53 -5 -68 -9 l-28 -7 27 -14 c31 -17 34 -17 34 -5 0 5 -8 11 -17 14 -10 2 0
4 22 4 22 0 87 6 145 14 79 10 99 15 80 21 -15 4 12 6 63 4 48 -2 93 -9 100
-14 7 -6 35 -12 62 -14 37 -2 51 -7 53 -20 4 -20 -38 -28 -83 -15 -49 14 -236
18 -289 6 -36 -9 -50 -9 -55 0 -6 8 -16 8 -39 0 -37 -13 -33 -30 6 -25 15 2
20 1 12 -1 -8 -3 -21 -10 -28 -15 -18 -16 -89 -1 -113 23 l-21 21 24 -6 c19
-6 20 -5 6 4 -12 7 -25 7 -45 -2 -20 -8 -24 -12 -12 -15 28 -7 37 -88 10 -88
-10 0 -35 -28 -38 -43 -2 -6 1 -12 6 -12 5 0 4 -11 -2 -25 -7 -14 -18 -25 -24
-25 -19 0 -15 -18 5 -22 20 -4 91 34 73 40 -6 2 -18 -1 -25 -8 -10 -7 -16 -8
-21 0 -3 5 0 10 8 10 8 0 21 8 28 17 10 11 10 14 2 9 -17 -10 -18 35 -1 52 17
17 33 15 18 -3 -7 -9 -10 -18 -6 -22 3 -3 6 -2 6 4 0 5 8 11 18 12 26 4 83 9
122 11 29 1 31 3 15 10 -11 4 -30 6 -43 3 -32 -7 -94 6 -86 18 3 6 36 10 73 8
131 -4 181 0 175 14 -5 14 -74 19 -74 5 0 -4 -7 -8 -15 -8 -24 0 -17 14 17 33
19 11 34 14 37 8 4 -6 12 -6 21 -1 19 12 217 13 224 1 4 -5 0 -12 -6 -14 -10
-4 -9 -6 1 -6 9 -1 6 -8 -10 -23 -17 -15 -19 -19 -6 -15 9 4 29 2 44 -4 22 -8
30 -6 47 11 12 11 15 20 8 20 -23 0 -12 18 18 29 33 11 35 18 18 56 -8 17 -19
25 -37 25 -14 0 -37 3 -51 5 -22 5 -22 4 -5 -4 19 -9 19 -10 -2 -10 -12 -1
-25 4 -28 9 -7 11 -141 11 -238 1z m293 -126 c0 -9 -6 -12 -16 -8 -13 5 -13 7
-2 14 17 11 18 11 18 -6z"/>
<path d="M6260 7819 c0 -6 -6 -9 -12 -8 -26 3 -38 -1 -38 -15 0 -13 39 -30 49
-21 2 3 -1 5 -8 5 -7 0 -9 5 -6 10 4 7 13 7 26 0 11 -6 17 -14 15 -19 -3 -4 9
-5 26 -3 24 4 29 2 23 -9 -5 -8 -5 -11 0 -7 4 4 15 2 23 -5 13 -10 14 -9 7 3
-5 9 -5 12 1 7 5 -5 10 -13 10 -18 1 -5 2 -12 3 -16 0 -4 8 2 16 13 15 18 15
18 8 0 -4 -13 -3 -17 5 -12 7 4 12 3 12 -2 0 -5 9 -12 20 -15 11 -3 20 -11 20
-17 0 -10 -4 -10 -15 0 -13 11 -14 10 -9 -4 3 -9 18 -16 33 -17 l26 -1 -25 -8
c-29 -9 -27 -11 17 -15 18 -1 39 -10 48 -19 15 -15 12 -20 -7 -17 -13 2 -9
-37 4 -45 6 -4 8 -3 5 3 -3 5 15 10 43 10 35 0 48 -3 43 -11 -3 -6 -2 -8 2 -3
11 10 66 -43 58 -56 -3 -6 -1 -7 5 -3 8 5 12 -1 12 -14 0 -12 -4 -19 -9 -16
-5 4 -7 -3 -4 -14 3 -11 13 -20 22 -19 9 0 11 3 4 5 -7 3 -10 16 -7 33 4 18 0
32 -10 40 -9 8 -16 20 -16 27 0 8 -7 14 -15 14 -8 0 -20 9 -27 20 -13 21 -48
28 -48 10 0 -5 -4 -10 -10 -10 -5 0 -7 7 -4 15 4 8 1 15 -5 15 -6 0 -11 8 -11
18 1 16 1 16 14 0 14 -19 66 -25 66 -8 0 6 -6 10 -13 10 -19 0 -77 30 -77 40
0 4 -10 10 -22 14 -13 3 -32 19 -43 36 -11 16 -26 30 -33 30 -7 0 -10 5 -7 10
3 6 1 10 -4 10 -6 0 -11 -4 -11 -10 0 -6 -8 -6 -22 0 -13 5 -40 12 -62 15 -26
4 -35 10 -28 17 6 6 16 7 28 0 24 -12 81 -26 72 -17 -13 13 -158 53 -158 44z
m33 -16 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M5634 7779 c-9 -15 1 -21 26 -17 21 3 21 3 1 15 -14 9 -22 9 -27 2z"/>
<path d="M5507 7764 c-3 -3 -3 -12 0 -20 3 -8 1 -14 -5 -14 -6 0 -12 5 -14 12
-3 8 -9 8 -24 0 -13 -8 -14 -11 -4 -12 11 0 11 -3 2 -12 -10 -10 -15 -10 -20
-1 -10 16 -58 -8 -54 -27 2 -8 -4 -11 -18 -7 -11 3 -23 0 -27 -5 -3 -7 -1 -8
5 -4 16 9 15 -7 0 -22 -9 -9 -9 -12 0 -12 7 0 12 5 12 10 0 6 7 10 15 10 8 0
15 5 15 10 0 6 8 10 18 10 10 0 24 6 30 12 10 10 12 8 9 -10 -2 -12 -12 -28
-22 -36 -18 -13 -18 -14 5 -26 17 -9 20 -14 9 -20 -7 -5 -18 -6 -24 -2 -5 4
-13 7 -17 7 -3 0 -10 4 -15 9 -5 5 -2 6 6 1 9 -5 12 -4 7 3 -8 13 -47 12 -62
-3 -12 -11 -7 -38 6 -30 4 3 13 -2 20 -9 10 -12 3 -14 -39 -13 -28 1 -51 4
-52 7 0 3 -2 13 -4 23 -2 12 3 17 19 17 24 0 71 29 48 30 -7 0 -10 5 -7 10 3
6 1 10 -4 10 -6 0 -11 -4 -11 -10 0 -5 -5 -10 -11 -10 -16 0 -59 -36 -52 -44
3 -3 -1 -6 -10 -6 -22 0 -172 -146 -158 -154 18 -12 -7 -49 -29 -43 -14 3 -17
1 -13 -11 4 -9 2 -13 -4 -9 -6 4 -17 -1 -24 -10 -10 -12 -10 -14 -1 -9 6 4 12
3 12 -2 0 -5 -9 -12 -20 -15 -11 -3 -20 -10 -20 -17 0 -6 5 -8 10 -5 14 9 13
-8 -2 -23 -7 -7 -9 -12 -4 -12 13 0 36 28 36 43 0 14 31 47 44 47 5 0 6 -9 3
-20 -11 -43 56 -50 94 -9 15 16 25 31 23 34 -2 3 -1 3 3 0 3 -3 15 7 25 22 10
16 18 22 18 15 0 -7 5 -10 10 -7 13 8 13 -25 0 -45 -8 -13 -11 -13 -19 -1 -9
14 -31 7 -31 -10 0 -6 5 -7 11 -3 9 5 10 -1 5 -22 l-7 -29 29 30 c16 17 28 33
26 37 -3 4 4 9 14 12 26 9 82 63 82 80 0 21 -39 15 -56 -9 -8 -11 -11 -28 -8
-39 5 -19 4 -19 -11 2 -16 22 -16 24 1 37 16 11 17 15 4 31 -8 10 -10 15 -4
11 6 -3 24 9 40 28 16 19 34 35 40 35 6 0 23 9 38 21 15 12 26 16 26 9 0 -9 6
-8 22 2 24 16 35 58 15 58 -8 0 -7 4 3 10 13 9 13 11 0 21 -12 8 -8 9 17 5 31
-5 65 16 45 28 -5 3 -7 19 -4 36 4 29 1 36 -11 24z m-82 -64 c3 -5 -1 -10 -10
-10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m75 -9 c0 -5 -9 -7
-20 -4 -11 3 -20 7 -20 9 0 2 9 4 20 4 11 0 20 -4 20 -9z m-246 -126 c-6 -15
-17 -19 -41 -16 -7 1 -13 -3 -13 -9 0 -13 36 -13 62 1 17 9 20 9 15 -4 -3 -8
-1 -20 4 -26 5 -6 6 -9 3 -6 -4 2 -21 -2 -40 -10 -32 -13 -33 -13 -43 17 -12
34 -12 35 21 54 31 17 38 17 32 -1z m-80 -96 c-10 -11 -27 -18 -38 -17 -18 3
-22 -4 -26 -37 -4 -30 -10 -41 -25 -44 -20 -3 -20 -3 -1 18 11 12 16 29 13 41
-3 11 -1 20 4 20 5 0 9 7 9 15 0 8 6 12 13 9 8 -3 25 4 38 15 29 23 39 8 13
-20z m141 -19 c-3 -5 -1 -10 5 -10 7 0 4 -7 -5 -16 -9 -9 -19 -13 -23 -10 -3
4 0 11 8 16 8 6 11 10 7 10 -9 0 1 20 9 20 3 0 2 -4 -1 -10z m-108 -19 c2 -20
-22 -35 -38 -25 -5 3 -9 -5 -9 -17 0 -12 -3 -20 -6 -16 -4 3 -13 -1 -21 -9
-20 -19 -21 -17 -15 28 3 32 9 39 36 47 46 14 52 13 53 -8z m-110 -86 c0 -8
-6 -15 -13 -15 -8 0 -11 6 -8 15 4 8 9 15 13 15 4 0 8 -7 8 -15z"/>
<path d="M5986 7748 c-8 -5 -26 -7 -40 -4 -23 4 -56 -18 -44 -29 2 -3 31 -1
64 3 42 5 60 12 62 23 3 17 -17 21 -42 7z"/>
<path d="M6249 7748 c-1 -7 0 -17 1 -23 1 -5 3 -16 3 -22 1 -25 11 -2 12 27 1
28 -14 44 -16 18z"/>
<path d="M6480 7742 c0 -5 12 -14 26 -20 14 -7 22 -17 19 -22 -4 -6 5 -10 20
-10 17 0 24 -4 19 -12 -5 -7 -3 -8 5 -4 7 5 18 1 24 -7 15 -19 57 -32 57 -18
0 6 -5 11 -11 11 -6 0 -26 12 -45 26 -19 15 -34 23 -34 19 0 -5 -8 -1 -17 7
-26 22 -63 40 -63 30z"/>
<path d="M6275 7730 c-14 -22 8 -38 46 -35 26 2 39 0 39 -9 0 -7 6 -21 14 -32
13 -18 14 -17 12 14 -1 21 2 32 12 32 7 0 10 5 7 10 -5 8 -11 7 -21 -1 -12
-10 -17 -8 -28 7 -14 19 -72 29 -81 14z m62 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z"/>
<path d="M5769 7668 c-14 -18 -35 -26 -24 -8 3 6 -4 10 -16 10 -18 0 -19 -2
-9 -15 10 -12 9 -15 -6 -16 -11 0 -35 -2 -54 -3 -19 -1 -41 2 -47 7 -9 7 -13
4 -13 -11 0 -24 24 -35 48 -23 18 9 98 30 144 38 35 6 44 30 13 35 -15 3 -28
-2 -36 -14z"/>
<path d="M5857 7670 c-16 -16 -13 -16 41 -13 80 6 83 8 27 19 -42 9 -53 8 -68
-6z"/>
<path d="M6008 7673 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6163 7672 c22 -3 26 -6 17 -16 -8 -11 -3 -15 21 -20 18 -3 34 -4 37
-1 3 3 -2 5 -11 5 -9 0 -17 5 -17 10 0 7 13 8 35 4 22 -4 35 -2 35 4 0 6 10
12 23 14 12 2 -21 4 -73 3 -52 0 -82 -1 -67 -3z"/>
<path d="M5630 7660 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M6085 7659 c22 -4 49 -8 60 -8 l20 0 -20 9 c-11 5 -38 8 -60 8 l-40
0 40 -9z"/>
<path d="M6324 7637 c21 -10 25 -16 15 -21 -8 -3 -4 -3 8 -1 12 3 24 1 28 -5
3 -6 -4 -10 -17 -10 -20 -1 -21 -2 -6 -11 13 -7 22 -5 36 9 18 18 17 19 -17
29 -68 22 -76 23 -47 10z"/>
<path d="M6250 7630 c0 -5 9 -10 19 -10 11 0 28 -3 38 -6 10 -3 7 2 -7 10 -29
18 -50 20 -50 6z"/>
<path d="M6650 7615 c-12 -15 -6 -35 12 -35 5 0 8 7 4 15 -3 9 0 15 9 15 8 0
12 -6 9 -13 -2 -7 7 -23 21 -35 14 -12 25 -29 25 -38 0 -14 3 -14 15 -4 9 7
18 10 21 7 9 -9 -6 -27 -23 -27 -10 0 -13 -6 -9 -16 3 -9 6 -18 6 -20 0 -2 7
-4 15 -4 8 0 15 7 15 15 0 9 6 18 14 21 8 3 17 13 21 22 3 9 3 13 -1 9 -4 -4
-14 2 -22 14 -10 15 -17 17 -27 9 -9 -8 -15 -8 -20 0 -3 5 -1 10 4 10 6 0 11
5 11 10 0 6 -10 8 -24 4 -26 -6 -46 13 -26 26 9 6 7 11 -8 19 -26 14 -27 14
-42 -4z"/>
<path d="M6433 7580 c21 -11 43 -17 49 -13 7 4 -2 12 -23 19 -53 19 -68 16
-26 -6z"/>
<path d="M6715 7590 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6410 7566 c0 -13 63 -33 84 -28 17 5 15 7 -14 13 -19 3 -43 10 -52
14 -10 4 -18 4 -18 1z"/>
<path d="M5435 7550 c-3 -6 1 -7 9 -4 9 3 16 1 16 -5 0 -6 -7 -11 -16 -11 -8
0 -13 -4 -9 -9 3 -5 -3 -12 -14 -16 -19 -6 -60 -45 -47 -45 4 0 26 15 49 33
23 19 51 37 61 41 22 8 20 11 -16 20 -15 3 -30 2 -33 -4z"/>
<path d="M6567 7539 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M6526 7518 c5 -8 2 -9 -12 -4 -22 8 -21 7 30 -33 21 -17 44 -31 50
-31 6 0 3 7 -6 14 -24 18 -23 29 2 21 12 -4 20 -2 20 5 0 6 -15 10 -35 8 -19
-1 -37 1 -41 5 -4 4 1 7 12 7 16 0 17 2 4 10 -21 13 -33 12 -24 -2z"/>
<path d="M6616 7522 c-2 -4 -1 -14 4 -22 7 -12 12 -12 22 -2 9 9 8 12 -6 12
-9 0 -14 5 -11 10 3 6 4 10 1 10 -3 0 -7 -4 -10 -8z"/>
<path d="M5370 7505 c-19 -23 -8 -26 20 -5 19 15 21 20 9 20 -9 0 -22 -7 -29
-15z"/>
<path d="M6578 7513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6808 7498 c-3 -7 -4 -30 -4 -50 1 -28 -3 -38 -16 -41 -10 -3 -16 -1
-12 4 3 5 -2 9 -11 9 -10 0 -13 -5 -9 -12 5 -8 2 -9 -9 -5 -12 5 -17 1 -17
-12 0 -22 26 -36 34 -18 3 9 7 8 15 -3 8 -12 6 -16 -9 -18 -11 -2 -20 -8 -20
-14 0 -8 64 -7 98 1 1 1 2 9 2 19 0 14 2 15 10 2 6 -9 10 -10 10 -3 0 7 -9 17
-20 23 -28 15 -45 60 -28 72 10 6 10 10 -2 17 -8 6 -11 13 -7 17 4 4 15 0 24
-9 13 -13 14 -19 4 -31 -7 -9 -8 -16 -2 -20 5 -4 13 3 16 15 8 25 -38 81 -47
57z"/>
<path d="M6620 7456 c0 -8 -5 -16 -10 -18 -6 -2 0 -9 12 -17 21 -13 22 -13 10
2 -9 10 -10 17 -2 22 13 8 13 25 0 25 -5 0 -10 -6 -10 -14z"/>
<path d="M6646 7455 c-8 -22 9 -60 36 -79 12 -9 19 -16 15 -16 -4 0 -2 -6 5
-14 7 -8 17 -11 21 -7 12 12 -2 38 -29 56 -13 8 -24 22 -24 30 0 8 -4 15 -10
15 -5 0 -7 7 -4 15 4 8 4 15 1 15 -3 0 -7 -7 -11 -15z"/>
<path d="M6860 7430 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5057 7423 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M6690 7426 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"/>
<path d="M6889 7424 c0 -5 -3 -30 -5 -56 -4 -38 -1 -48 11 -49 51 -4 91 -39
46 -39 -11 0 -23 -4 -26 -10 -3 -5 -2 -10 2 -10 5 0 9 -8 10 -17 2 -15 5 -34
12 -75 0 -5 8 -8 17 -8 12 0 15 7 12 25 -3 13 -1 22 3 19 5 -3 9 -13 9 -23 0
-10 12 -26 26 -36 27 -17 27 -17 22 8 -3 14 -1 32 4 39 7 10 8 8 4 -4 -3 -10
-2 -18 2 -18 4 0 19 -29 32 -65 13 -36 28 -65 33 -65 4 0 7 3 5 8 -13 26 -29
64 -49 115 -16 39 -25 53 -32 45 -7 -10 -10 -10 -14 1 -5 11 -7 11 -14 0 -5
-8 -7 6 -6 35 2 39 -2 51 -18 60 -11 6 -23 15 -26 20 -4 5 -14 6 -23 3 -12 -5
-15 0 -14 19 2 15 7 23 15 20 7 -3 13 -4 13 -3 0 9 -50 69 -51 61z m21 -34 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m80
-115 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z"/>
<path d="M6730 7322 c0 -17 25 -32 55 -32 19 0 35 -7 43 -20 17 -27 37 -25 38
4 1 15 -4 23 -15 23 -39 0 -52 4 -46 12 3 6 -5 8 -19 5 -14 -2 -28 0 -31 6 -7
12 -25 13 -25 2z"/>
<path d="M5021 7289 c-10 -28 -9 -30 12 -23 19 6 20 5 8 -10 -7 -9 -20 -16
-27 -16 -8 0 -14 -6 -14 -13 0 -8 -9 -22 -20 -32 -11 -10 -20 -13 -20 -7 0 6
5 14 11 17 5 4 8 12 6 18 -2 7 3 21 12 31 16 19 15 19 -11 1 -27 -17 -31 -26
-30 -70 1 -17 0 -17 -4 -2 -3 9 -9 17 -14 17 -5 0 3 20 17 46 14 25 23 47 20
50 -2 3 -16 -17 -30 -44 -17 -33 -22 -53 -16 -63 12 -19 11 -49 -1 -49 -5 0
-10 12 -11 28 0 24 -1 25 -9 7 -13 -30 -12 -32 13 -55 19 -19 20 -22 6 -30
-23 -13 -47 -72 -31 -78 7 -2 12 -17 12 -34 0 -22 -4 -29 -15 -25 -8 4 -17 2
-20 -3 -3 -4 0 -10 6 -12 8 -3 8 -10 -2 -26 -12 -19 -12 -24 1 -32 19 -12 26
-4 11 14 -14 17 -1 28 18 16 9 -5 12 -17 7 -36 -5 -22 -4 -26 4 -14 9 13 11
12 8 -5 -2 -11 -9 -20 -15 -20 -7 0 -19 -9 -27 -20 -15 -19 -15 -19 -16 6 0
25 -25 58 -35 47 -6 -6 -13 -47 -14 -81 -1 -16 -5 -26 -11 -22 -6 4 -10 -18
-10 -59 0 -36 5 -68 10 -71 6 -4 10 -202 10 -561 l0 -554 478 2 477 3 3 73 3
72 -401 0 -400 0 0 26 c0 14 4 23 10 19 6 -3 10 2 10 12 -1 15 -4 14 -21 -7
-18 -22 -21 -22 -17 -5 1 11 12 28 23 38 11 10 14 16 8 12 -9 -4 -13 4 -13 30
0 22 4 34 10 30 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5 0 -10 9 -10 20 0 11 5
20 10 20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 5 20 10 20
6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 9
10 20 0 11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 9 10 20 0
11 -4 20 -10 20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 -4 20
-10 20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10
9 -10 20 0 11 5 20 10 20 6 0 10 6 10 14 0 9 -7 12 -20 9 -14 -4 -20 0 -20 11
0 10 6 13 15 10 8 -4 15 -2 15 4 0 6 -6 13 -12 15 -10 4 -10 6 0 6 6 1 12 8
12 17 0 8 -3 13 -7 11 -5 -3 -9 37 -10 89 -1 75 1 94 13 94 8 0 14 10 14 24 0
36 39 229 48 236 4 3 12 19 18 35 6 17 15 39 20 50 7 18 6 18 -9 6 -10 -8 -17
-9 -17 -3 0 6 5 14 10 17 6 3 10 14 10 24 0 10 10 31 21 46 20 25 21 27 3 24
-10 -2 -24 0 -30 4 -7 4 -17 0 -24 -8 -10 -12 -9 -15 4 -15 9 0 16 -8 16 -17
0 -15 -2 -16 -10 -3 -8 12 -10 11 -10 -7 0 -13 4 -23 10 -23 6 0 5 -10 -2 -25
-17 -37 -30 -30 -23 13 3 20 2 31 -1 25 -13 -29 -44 -7 -44 31 0 20 71 86 92
86 11 0 16 -4 13 -10 -4 -7 2 -7 18 -1 27 11 37 6 37 -18 0 -11 -3 -12 -8 -4
-5 8 -12 8 -25 -2 -16 -11 -15 -12 8 -12 38 0 53 23 28 47 -15 14 -23 16 -32
7 -8 -8 -14 -8 -22 0 -6 6 -17 9 -24 6 -9 -3 -12 2 -8 15 8 34 -24 35 -36 1z
m-40 -171 c1 -18 0 -43 0 -55 -2 -14 5 -24 18 -28 23 -7 27 -20 10 -31 -6 -3
-9 -22 -6 -43 5 -39 -21 -153 -33 -146 -5 3 -10 -1 -13 -9 -7 -18 -61 -15 -63
4 0 9 1 10 3 3 3 -7 9 -13 14 -13 6 0 8 4 5 9 -3 5 5 29 19 54 14 24 23 51 19
60 -3 8 1 26 10 39 10 16 12 28 6 38 -5 8 -10 11 -10 6 0 -5 -12 3 -26 17 -15
15 -24 33 -21 44 4 14 5 13 6 -4 0 -13 6 -23 12 -23 5 0 8 4 4 9 -3 5 -1 13 5
16 6 4 8 16 4 27 -4 14 -2 18 5 14 6 -4 11 -2 11 3 0 6 -5 11 -10 11 -12 0 10
29 23 30 4 0 7 -15 8 -32z m69 -71 c0 -7 -4 -6 -10 3 -8 12 -10 12 -10 1 0 -8
-3 -12 -6 -8 -9 9 6 28 17 22 5 -4 9 -12 9 -18z m-205 -216 c-3 -6 -11 -8 -17
-5 -6 4 -5 9 3 15 16 10 23 4 14 -10z m135 -61 c0 -11 -2 -20 -4 -20 -2 0 -6
9 -9 20 -3 11 -1 20 4 20 5 0 9 -9 9 -20z"/>
<path d="M4970 6949 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"/>
<path d="M4987 6933 c-4 -3 -7 -15 -7 -25 0 -10 -3 -18 -8 -18 -4 0 -13 -9
-20 -20 -10 -17 -10 -20 6 -20 13 0 22 12 31 41 12 40 11 56 -2 42z"/>
<path d="M5156 7291 c-3 -5 1 -14 9 -21 8 -7 15 -11 16 -9 6 32 5 39 -6 39 -8
0 -16 -4 -19 -9z"/>
<path d="M6772 7263 c6 -7 14 -10 19 -7 15 9 10 20 -10 20 -15 0 -17 -3 -9
-13z"/>
<path d="M6807 7243 c-18 -5 -18 -6 2 -19 11 -8 18 -20 16 -27 -3 -6 3 -25 14
-40 l18 -29 7 27 c4 14 10 23 15 20 5 -2 11 10 13 28 2 23 -1 34 -9 34 -11 0
-18 -17 -14 -39 0 -4 -6 -5 -14 -2 -8 4 -15 17 -15 30 0 24 -1 25 -33 17z m44
-65 c11 -21 11 -22 -4 -9 -10 7 -17 17 -17 22 0 15 9 10 21 -13z"/>
<path d="M5103 7168 c-10 -13 -24 -58 -18 -58 2 0 11 14 19 32 17 33 16 48 -1
26z"/>
<path d="M6988 7105 c2 -19 8 -38 13 -41 5 -3 9 -14 9 -24 0 -13 5 -17 15 -14
10 4 15 0 16 -12 0 -11 3 -14 6 -6 8 19 17 14 31 -18 8 -17 18 -28 23 -24 14
8 11 40 -6 54 -8 7 -15 18 -15 26 0 7 -5 16 -12 20 -8 5 -9 0 -6 -14 4 -13 2
-20 -4 -16 -5 4 -7 21 -3 41 4 24 3 33 -4 28 -6 -3 -11 -18 -11 -33 -1 -26 -1
-26 -15 -8 -8 11 -15 29 -15 40 0 17 2 18 10 6 5 -8 10 -10 10 -4 0 13 -21 34
-35 34 -7 0 -9 -14 -7 -35z"/>
<path d="M4864 7098 c-11 -46 -11 -129 1 -125 8 3 18 157 10 157 -1 0 -6 -15
-11 -32z"/>
<path d="M6867 7099 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M6899 7080 c-2 -13 -2 -37 0 -52 1 -10 3 -10 10 0 6 9 8 5 8 -15 0
-15 8 -65 18 -110 15 -67 21 -81 32 -72 18 15 17 39 -2 39 -8 0 -15 5 -15 10
0 6 6 10 14 10 10 0 13 8 10 26 -5 26 12 58 25 49 9 -5 11 21 4 58 -6 32 -23
24 -26 -12 -1 -11 -10 -24 -20 -27 -13 -4 -16 -11 -11 -22 3 -10 8 -28 10 -42
3 -18 0 -15 -10 10 -18 44 -20 71 -4 68 7 -2 14 8 16 22 2 20 1 22 -7 10 -9
-12 -11 -11 -11 7 0 12 -5 25 -11 29 -8 4 -7 9 2 15 10 6 8 9 -8 9 -13 0 -24
-4 -24 -10z"/>
<path d="M4835 7008 c-13 -43 -26 -120 -21 -125 3 -3 9 14 13 38 l8 44 6 -45
c5 -36 6 -31 5 28 0 71 -3 84 -11 60z"/>
<path d="M7040 6976 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>
<path d="M7120 6965 c-1 -5 -3 -18 -5 -27 -2 -10 1 -18 6 -18 5 0 8 -10 8 -22
-1 -20 -2 -21 -6 -4 -4 17 -8 14 -19 -13 -16 -37 -19 -81 -5 -81 4 0 6 11 3
25 -2 15 0 25 7 25 7 0 11 -6 10 -12 -1 -7 1 -25 5 -39 5 -17 2 -44 -8 -73 -9
-26 -16 -54 -15 -64 1 -9 7 1 15 23 13 38 14 34 11 -75 -3 -75 -1 -101 4 -75
4 22 8 91 8 153 1 75 5 112 12 112 6 0 8 7 5 16 -3 9 -8 35 -11 58 -6 45 -22
107 -25 91z m17 -152 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z"/>
<path d="M7016 6944 c-8 -22 -8 -26 4 -19 5 3 3 -13 -6 -37 -11 -31 -12 -49
-4 -72 5 -16 10 -49 10 -73 0 -27 4 -42 10 -38 6 3 10 -3 10 -14 0 -12 7 -21
15 -21 8 0 15 5 15 10 0 6 7 10 15 10 8 0 15 9 15 19 0 11 3 28 6 38 5 15 4
15 -5 3 -6 -8 -11 -10 -12 -5 0 6 -5 -1 -10 -15 -8 -22 -8 -21 -4 8 2 17 0 32
-5 32 -5 0 -10 -12 -12 -27 -2 -16 -4 2 -5 38 0 36 -4 75 -7 87 -5 13 -3 22 3
22 6 0 11 -20 11 -46 0 -25 4 -43 8 -40 4 2 7 32 6 66 -2 54 0 59 12 43 12
-17 13 -16 14 11 0 25 -3 28 -25 24 -14 -3 -28 -1 -31 3 -8 14 -21 11 -28 -7z
m44 -24 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z"/>
<path d="M6974 6744 c12 -63 5 -195 -10 -209 -6 -5 -14 -20 -17 -33 -4 -17 -1
-22 11 -20 24 4 29 -40 7 -56 -18 -13 -17 -51 0 -51 19 0 35 -16 35 -36 0 -10
5 -19 10 -19 14 0 12 64 -2 79 -7 6 -14 29 -14 49 -1 20 -5 49 -9 65 -6 23 -4
27 14 27 22 0 28 -14 11 -25 -16 -10 -12 -28 16 -60 22 -26 25 -36 20 -74 -3
-24 -10 -49 -16 -56 -6 -7 -4 -19 6 -34 10 -14 14 -32 10 -49 -5 -21 -4 -24 5
-12 8 12 9 10 4 -10 -18 -66 -13 -69 13 -10 34 77 48 125 32 115 -5 -3 -10
-15 -10 -25 0 -10 -5 -22 -11 -26 -9 -5 -10 1 -5 21 7 26 6 27 -10 14 -16 -13
-17 -12 -10 6 7 17 10 78 7 158 -1 10 -5 16 -10 13 -5 -3 -15 2 -22 11 -10 12
-10 14 -1 9 6 -4 12 -3 12 1 0 20 -26 63 -35 59 -6 -2 -11 5 -11 15 0 11 3 19
7 19 4 0 7 31 8 69 1 51 -3 74 -15 85 -21 22 -25 20 -20 -10z"/>
<path d="M7152 6685 c0 -81 1 -86 10 -48 7 28 7 57 0 90 -9 46 -10 44 -10 -42z"/>
<path d="M4977 6723 c-4 -3 -6 -25 -5 -47 l1 -41 7 45 c5 34 7 19 8 -62 l2
-108 413 -2 412 -3 -1 -537 c-1 -371 -6 -559 -14 -610 -54 -319 -284 -604
-590 -731 -372 -153 -802 -63 -1077 226 -144 151 -228 315 -262 511 -20 110
-14 293 13 398 84 333 326 594 647 699 l99 32 0 110 0 109 -42 -7 c-62 -10
-213 -62 -283 -97 -226 -113 -433 -320 -544 -545 -43 -85 -86 -221 -107 -328
-24 -133 -15 -364 20 -498 62 -233 164 -406 340 -574 181 -174 399 -280 649
-317 132 -20 212 -20 345 0 321 47 596 213 798 481 68 89 160 283 189 398 39
154 46 298 43 925 l-3 575 -526 3 c-289 1 -529 -1 -532 -5z"/>
<path d="M7040 6603 l1 -38 13 30 c14 31 12 45 -5 45 -5 0 -9 -17 -9 -37z"/>
<path d="M7146 6549 c-2 -8 -8 -36 -11 -64 -6 -38 -9 -45 -15 -30 -10 26 -17
8 -24 -58 -10 -110 32 -27 53 105 9 50 7 77 -3 47z"/>
<path d="M7084 6528 c-8 -13 -13 -78 -5 -78 11 0 30 38 31 63 0 28 -13 36 -26
15z"/>
<path d="M6941 6453 c0 -12 -4 -30 -7 -40 -5 -17 -5 -17 6 0 6 10 9 28 7 40
-4 22 -4 22 -6 0z"/>
<path d="M6906 6330 c-20 -55 -21 -64 -6 -66 6 -1 15 -2 21 -3 6 0 8 -10 4
-21 -8 -26 16 -27 38 -2 21 23 21 29 0 50 -16 16 -16 16 -11 -5 4 -16 2 -20
-9 -16 -10 4 -12 13 -8 27 16 49 17 66 6 66 -7 0 -9 5 -6 10 3 6 2 10 -2 10
-5 0 -17 -22 -27 -50z m34 5 c0 -8 -4 -15 -10 -15 -5 0 -10 -10 -10 -22 0 -12
-3 -19 -6 -15 -9 8 6 67 17 67 5 0 9 -7 9 -15z"/>
<path d="M7000 6281 c0 -41 -29 -72 -77 -80 -34 -7 -40 -5 -36 7 3 7 0 18 -7
22 -10 6 -22 -7 -43 -47 -16 -30 -26 -57 -23 -60 3 -3 10 5 15 18 10 24 10 24
10 -5 1 -25 -2 -28 -25 -23 -21 4 -25 1 -21 -14 3 -11 1 -16 -5 -13 -15 10
-43 -37 -44 -75 -1 -37 -21 -56 -42 -39 -9 8 -16 8 -25 -1 -13 -13 -17 -61 -5
-61 4 0 8 -8 8 -18 0 -29 12 -34 28 -10 14 19 13 22 -1 33 -16 12 -13 25 5 25
5 0 6 -6 3 -12 -4 -7 4 -1 17 15 13 15 29 27 36 27 16 0 16 27 0 32 -8 3 -4 9
9 17 19 9 22 9 17 -2 -8 -22 44 -12 65 11 17 20 17 20 28 -9 6 -16 7 -29 2
-29 -5 0 -9 -11 -9 -24 0 -13 -12 -35 -26 -50 -42 -41 -63 -32 -23 10 l21 22
-22 7 c-16 5 -27 0 -45 -21 -13 -15 -21 -32 -19 -39 3 -6 -2 -17 -10 -24 -8
-7 -13 -18 -10 -25 3 -7 -3 -19 -12 -27 -16 -12 -17 -12 -11 3 7 18 -24 35
-44 23 -5 -4 -10 -1 -8 6 1 8 1 23 0 34 -1 16 -3 17 -10 6 -5 -8 -7 -20 -4
-27 2 -6 -1 -15 -8 -20 -7 -4 -10 -3 -5 4 3 6 2 13 -4 17 -5 3 -10 13 -10 21
0 8 3 13 8 11 4 -3 8 6 8 19 1 13 -2 21 -7 18 -5 -3 -9 -12 -9 -20 0 -8 -4
-13 -9 -10 -4 3 -35 -13 -67 -37 -32 -23 -88 -57 -124 -75 -36 -18 -58 -31
-48 -28 23 7 30 -4 15 -22 -10 -13 -9 -14 2 -8 8 5 16 3 22 -5 4 -7 11 -10 15
-7 3 4 -1 15 -10 24 -16 15 -16 17 3 30 10 8 37 20 58 27 21 7 37 18 35 25 -1
7 5 12 13 12 9 0 12 -6 9 -15 -4 -8 -1 -15 6 -15 8 0 10 9 5 27 -5 23 -4 25 9
14 17 -14 46 -8 46 9 0 7 -7 7 -21 -1 -17 -9 -20 -8 -17 3 6 16 29 21 47 9 12
-8 12 -12 2 -25 -15 -17 -8 -22 39 -28 14 -2 24 -9 22 -16 -1 -8 2 -11 8 -7 6
4 17 2 24 -4 9 -8 18 -4 35 15 12 14 19 29 16 34 -4 6 -1 10 5 10 6 0 9 4 6 9
-3 5 3 11 13 14 27 7 37 -24 14 -41 -10 -8 -27 -20 -37 -28 -11 -7 -16 -17
-12 -21 4 -4 30 17 59 47 30 31 44 50 32 46 -16 -6 -14 -1 9 23 34 36 37 37
30 14 -4 -14 -2 -13 7 2 7 11 15 22 19 25 27 21 53 92 33 89 -27 -4 -53 16
-53 39 0 14 -3 23 -6 19 -4 -3 -13 1 -21 9 -11 11 -13 21 -5 43 7 19 7 32 0
39 -6 6 -6 12 1 16 6 4 11 0 11 -8 0 -12 7 -14 33 -9 17 3 36 10 42 14 14 14
35 11 35 -5 0 -8 -11 -18 -24 -23 -19 -7 -22 -12 -15 -30 11 -31 29 -29 29 2
0 14 5 25 10 25 6 0 10 -5 10 -11 0 -5 4 -8 10 -4 5 3 7 12 4 19 -3 7 2 19 11
26 19 16 19 30 0 30 -17 0 -20 25 -5 35 6 3 10 17 10 31 0 13 -4 24 -10 24 -5
0 -10 -13 -10 -29z m-153 -198 c-2 -5 -7 -16 -11 -27 -5 -14 -9 -16 -20 -7
-10 8 -20 6 -41 -11 l-28 -21 13 22 c6 13 21 26 31 28 10 3 19 9 19 14 0 5 9
9 21 9 11 0 18 -3 16 -7z m63 -122 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0
9 5 16 10 16 6 0 10 -4 10 -9z"/>
<path d="M6895 6250 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7015 6170 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6870 6149 c0 -5 5 -7 10 -4 6 3 10 1 10 -4 0 -6 -4 -11 -10 -11 -5
0 -10 -8 -10 -17 0 -15 2 -14 18 3 24 24 24 44 0 44 -10 0 -18 -5 -18 -11z"/>
<path d="M7010 6126 c-10 -19 -16 -23 -22 -14 -6 10 -8 10 -8 -1 0 -8 -7 -11
-20 -8 -21 5 -28 -7 -10 -18 6 -3 10 -11 10 -16 0 -6 -4 -8 -9 -5 -5 4 -4 -5
2 -18 13 -28 24 -20 56 43 24 46 25 80 1 37z m-28 -68 c-7 -7 -12 -8 -12 -2 0
14 12 26 19 19 2 -3 -1 -11 -7 -17z"/>
<path d="M6710 6005 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M6600 5810 c0 -5 -9 -10 -20 -10 -11 0 -20 -5 -20 -12 0 -9 -3 -9
-12 0 -14 14 -42 1 -75 -35 -24 -25 -30 -39 -13 -28 19 12 80 -6 80 -24 0 -14
2 -14 10 -1 7 11 5 18 -5 26 -20 15 -19 19 5 21 11 1 20 -2 20 -7 0 -5 7 -7
16 -3 13 5 13 7 2 14 -10 7 -9 9 5 9 14 0 17 5 12 20 -4 11 -2 20 4 20 6 0 11
5 11 10 0 6 -4 10 -10 10 -5 0 -10 -4 -10 -10z m-47 -47 c-7 -2 -21 -2 -30 0
-10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M6452 5771 c-21 -16 -22 -17 -3 -17 11 0 18 3 15 7 -2 4 2 9 8 12 7
2 11 7 8 10 -3 3 -16 -2 -28 -12z"/>
<path d="M6696 5774 c-3 -8 -19 -17 -36 -20 -17 -4 -33 -11 -37 -17 -4 -6 -1
-7 8 -3 8 4 3 -1 -11 -13 l-25 -20 24 -9 c20 -7 30 -4 50 14 14 12 36 31 49
41 22 18 22 18 3 30 -16 10 -20 9 -25 -3z m-36 -59 c-7 -8 -17 -15 -23 -15 -5
0 -2 9 8 20 21 23 35 19 15 -5z"/>
<path d="M6340 5741 c-8 -6 -18 -7 -24 -4 -5 3 -6 1 -2 -6 6 -8 1 -10 -17 -6
-15 4 -28 2 -32 -6 -5 -7 -4 -10 2 -6 11 7 63 -14 63 -24 0 -8 -75 13 -85 23
-11 11 -35 -3 -35 -21 0 -14 -2 -14 -11 -1 -9 12 -10 12 -5 -3 3 -10 6 -52 6
-93 0 -98 18 -95 23 4 l4 77 -1 -83 c-1 -83 7 -99 34 -67 7 8 23 15 35 15 13
0 25 4 27 9 2 5 23 11 48 14 49 5 100 25 100 39 0 4 7 8 15 8 14 0 91 36 122
57 8 6 -3 9 -27 10 -26 1 -38 -2 -34 -10 2 -7 -10 -20 -27 -30 -36 -20 -53
-14 -23 8 17 13 17 14 2 15 -10 0 -18 -4 -18 -10 0 -17 -48 -11 -55 8 -6 15
-8 15 -18 1 -19 -25 -42 -29 -55 -9 -9 14 -8 21 4 30 10 9 11 16 4 25 -8 9 -8
15 0 20 12 7 14 25 3 25 -5 0 -15 -4 -23 -9z m-65 -61 c-3 -5 -14 -10 -23 -10
-15 0 -15 2 -2 10 20 13 33 13 25 0z m305 -14 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-280 -22 c0 -8 5 -12 10 -9 6 3 10
1 10 -5 0 -6 3 -9 8 -7 4 3 16 1 27 -4 15 -6 16 -8 4 -8 -9 -1 -22 -8 -29 -16
-11 -13 -8 -14 21 -10 20 2 14 -1 -16 -9 -60 -17 -95 -19 -95 -7 0 5 11 12 25
15 l25 7 -26 24 c-29 27 -25 45 12 45 15 0 24 -6 24 -16z m134 -10 c13 -5 14
-9 5 -20 -6 -8 -8 -14 -3 -14 12 0 -19 -20 -31 -20 -5 0 -3 5 5 10 10 6 11 10
3 10 -7 0 -13 9 -13 20 0 21 8 24 34 14z"/>
<path d="M6380 5711 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M6411 5693 c0 -13 2 -14 6 -4 5 11 7 11 14 0 7 -11 9 -10 9 4 0 9 -7
17 -15 17 -8 0 -15 -8 -14 -17z"/>
<path d="M5782 5605 c-1 -44 2 -85 6 -92 4 -7 6 20 4 60 -5 108 -9 122 -10 32z"/>
<path d="M6493 5673 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4790 5505 c0 -7 170 -9 496 -7 274 1 495 5 492 7 -10 11 -988 10
-988 0z"/>
<path d="M6321 4599 c-154 -47 -233 -227 -176 -400 26 -77 103 -152 181 -175
73 -21 177 -14 240 17 l45 21 -3 71 c-2 55 -7 72 -18 75 -11 2 -19 -11 -27
-41 -22 -80 -110 -135 -190 -117 -77 18 -103 87 -103 273 0 127 10 174 46 216
68 81 211 34 245 -79 9 -28 17 -40 28 -38 13 3 16 17 16 73 l0 70 -39 19 c-45
22 -192 31 -245 15z"/>
<path d="M8758 4513 c-27 -4 -28 -7 -28 -59 0 -51 -1 -54 -25 -54 -29 0 -25
-25 6 -33 18 -5 19 -15 19 -142 0 -153 9 -184 60 -205 24 -10 40 -10 70 -1 44
13 97 68 81 84 -7 7 -16 5 -29 -6 -22 -20 -53 -22 -70 -5 -8 8 -12 55 -12 145
l0 133 45 0 c33 0 45 4 45 15 0 11 -12 15 -45 15 l-45 0 0 60 c0 66 2 64 -72
53z"/>
<path d="M6775 4394 c-72 -37 -105 -95 -105 -184 0 -104 46 -166 143 -190 91
-23 188 23 222 105 31 72 10 192 -41 237 -53 46 -161 62 -219 32z m133 -39
c42 -53 36 -260 -9 -297 -54 -45 -104 -3 -115 98 -9 85 8 193 32 211 29 21 70
16 92 -12z"/>
<path d="M7126 4397 c-56 -13 -68 -32 -37 -53 19 -14 21 -24 21 -138 0 -92 -4
-127 -15 -142 -29 -38 -20 -44 65 -44 66 0 80 3 80 16 0 8 -7 22 -15 30 -12
12 -15 41 -15 125 0 96 2 111 20 129 27 27 75 26 94 -2 23 -32 23 -240 1 -258
-8 -7 -15 -19 -15 -26 0 -11 19 -14 85 -14 76 0 84 2 78 18 -4 9 -13 23 -20
30 -9 10 -13 49 -13 138 0 138 -11 177 -56 194 -56 22 -105 10 -156 -38 l-27
-25 6 36 c6 42 3 43 -81 24z"/>
<path d="M7535 4396 c-54 -13 -72 -39 -36 -51 20 -6 21 -14 21 -139 0 -107 -3
-136 -15 -146 -8 -7 -15 -19 -15 -26 0 -11 19 -14 80 -14 66 0 80 3 80 16 0 8
-7 22 -15 30 -12 12 -15 41 -15 125 0 96 2 111 20 129 27 27 75 26 94 -2 22
-31 23 -225 1 -254 -29 -38 -20 -44 65 -44 66 0 80 3 80 16 0 8 -7 22 -15 30
-12 12 -15 43 -15 140 0 138 -11 177 -56 194 -53 20 -121 5 -153 -34 -20 -24
-28 -19 -23 14 5 34 -1 35 -83 16z"/>
<path d="M8025 4396 c-109 -50 -148 -199 -79 -299 40 -57 109 -92 167 -83 74
11 148 73 133 112 -5 13 -11 12 -37 -10 -23 -20 -41 -26 -77 -26 -26 0 -54 6
-62 13 -27 20 -50 60 -50 84 0 23 2 23 126 23 l126 0 -7 48 c-8 56 -30 98 -67
125 -35 26 -130 33 -173 13z m116 -46 c10 -14 20 -44 24 -67 l7 -43 -76 0
c-86 0 -90 6 -61 78 28 66 72 80 106 32z"/>
<path d="M8435 4391 c-162 -75 -149 -315 20 -371 68 -22 161 13 191 72 23 45
4 58 -36 24 -54 -45 -145 -28 -168 31 -44 111 2 233 88 233 27 0 28 -2 23 -35
-7 -41 9 -65 43 -65 31 0 56 27 52 58 -7 64 -126 93 -213 53z"/>
<path d="M5923 4337 c26 -22 20 -30 -9 -11 -19 13 -28 14 -37 5 -8 -8 -7 -11
4 -12 7 0 1 -7 -16 -14 -16 -7 -34 -11 -41 -8 -6 2 -19 -7 -28 -21 -18 -29
-10 -36 9 -9 11 15 15 15 29 4 19 -16 22 -45 4 -38 -7 2 -12 12 -10 21 3 15
-1 14 -27 -5 -17 -13 -31 -30 -31 -38 0 -13 -2 -13 -9 -3 -5 9 -15 -2 -30 -38
-12 -28 -18 -56 -14 -63 17 -26 -10 -97 -36 -97 -12 0 -13 -2 -3 -8 10 -7 10
-14 -3 -38 -17 -33 -19 -48 -5 -39 6 3 6 -5 0 -22 -4 -15 -11 -55 -13 -88 -3
-34 -2 -49 2 -35 7 23 8 21 15 -18 5 -24 5 -46 2 -49 -3 -4 -7 2 -7 13 -2 36
-18 -61 -19 -116 -1 -66 -40 -154 -97 -218 -13 -14 -23 -28 -23 -32 0 -13 -75
-57 -108 -65 -18 -3 -30 -11 -26 -16 8 -14 -19 -10 -40 6 -11 8 -32 15 -48 15
-15 0 -28 4 -28 10 0 5 7 7 16 3 14 -5 14 -3 3 15 -10 15 -16 18 -22 9 -5 -8
-7 -5 -6 8 1 11 -1 27 -4 35 -3 8 -8 22 -11 30 -4 8 -4 -1 -1 -20 4 -25 2 -31
-5 -20 -13 19 -13 57 0 65 6 4 6 15 0 31 -7 19 -6 40 5 77 8 28 15 61 15 74 0
13 4 23 9 23 5 0 14 15 20 33 6 17 21 44 33 57 32 36 31 45 -8 48 -39 2 -53
-16 -34 -45 10 -16 10 -15 5 5 -7 23 9 31 18 9 7 -18 -7 -40 -27 -43 -12 -2
-16 7 -16 33 0 34 -1 35 -35 31 -27 -3 -32 -1 -23 9 6 8 19 11 31 7 11 -4 23
-1 28 7 4 7 23 14 41 14 18 1 41 5 52 10 12 5 21 3 26 -5 5 -7 21 -13 38 -13
27 0 29 -3 27 -36 -3 -51 -4 -53 -16 -46 -6 4 -9 1 -8 -6 1 -8 -1 -26 -5 -41
-10 -35 2 -74 17 -59 14 14 37 14 37 0 0 -6 -7 -9 -15 -5 -8 3 -15 1 -15 -4 0
-6 11 -10 24 -10 17 0 27 9 36 30 7 17 16 30 21 30 5 0 9 7 9 15 0 13 -4 14
-20 5 -16 -9 -18 -8 -14 5 3 8 10 12 15 9 5 -3 9 3 10 13 0 16 1 16 9 -2 7
-18 8 -16 12 10 l5 30 2 -31 c0 -18 -7 -42 -19 -57 -11 -14 -20 -31 -20 -37 0
-23 42 40 52 78 15 54 13 88 -7 118 -13 21 -19 24 -26 13 -6 -10 -9 -8 -9 9 0
32 -41 45 -130 39 -85 -4 -137 -28 -202 -92 -28 -28 -37 -44 -32 -59 4 -16 3
-18 -5 -6 -8 12 -12 9 -20 -13 -6 -16 -11 -33 -11 -38 0 -5 -4 -9 -10 -9 -5 0
-10 -7 -10 -15 0 -8 5 -14 12 -12 6 1 14 -6 16 -17 2 -11 0 -16 -7 -12 -6 4
-11 1 -11 -8 0 -8 5 -16 12 -18 9 -3 9 -15 1 -49 -6 -24 -11 -34 -12 -23 0 12
-7 27 -15 33 -12 10 -18 6 -32 -21 -9 -18 -23 -41 -31 -50 -8 -9 -12 -21 -9
-26 8 -13 -3 -42 -16 -42 -6 0 -7 5 -2 13 5 7 -1 1 -13 -13 -13 -14 -23 -31
-23 -37 0 -7 -5 -13 -11 -13 -18 0 -56 -35 -51 -48 2 -6 -1 -12 -6 -12 -6 0
-12 5 -14 11 -2 7 -13 9 -26 5 -12 -4 -20 -11 -17 -16 2 -4 -9 -10 -25 -14
-17 -3 -30 -12 -30 -19 0 -10 -8 -13 -25 -9 -31 6 -27 28 4 25 15 -2 20 1 15
9 -4 6 -3 8 2 5 6 -3 16 -2 24 4 11 6 9 9 -11 9 -15 0 -38 9 -51 20 -14 10
-28 16 -32 12 -4 -3 -4 2 0 11 4 12 2 17 -7 15 -8 -2 -13 2 -12 9 2 7 -4 10
-12 7 -10 -4 -15 0 -15 11 0 9 5 13 10 10 6 -3 10 1 10 9 0 9 -7 16 -15 16 -8
0 -15 8 -15 18 0 14 2 15 10 2 9 -14 15 -7 10 15 -1 6 4 4 11 -2 7 -7 22 -13
34 -13 16 0 17 2 5 10 -13 8 -13 10 -1 10 10 0 6 9 -12 28 -15 15 -27 33 -27
40 0 17 -11 15 -27 -5 -7 -10 -13 -13 -12 -8 1 6 1 26 -1 45 -1 29 -3 32 -10
15 -7 -17 -9 -17 -9 -3 -1 10 -6 17 -12 15 -6 -1 -14 4 -16 11 -4 10 2 13 18
10 22 -3 24 0 23 39 0 33 2 39 12 28 8 -8 11 -26 8 -46 -3 -19 -1 -45 5 -59
10 -23 11 -22 5 10 -3 20 -2 30 3 23 6 -8 12 -1 19 22 7 22 7 32 1 28 -6 -4
-8 1 -4 11 4 10 7 23 7 29 0 7 5 6 10 -3 8 -12 10 -10 9 10 -3 43 0 58 17 64
15 6 15 4 -1 -20 -24 -37 -11 -41 15 -5 12 17 18 31 13 32 -4 0 14 7 42 14 27
8 51 12 54 10 2 -3 -1 -5 -7 -5 -12 0 -3 -36 24 -97 18 -40 18 -63 0 -63 -8 0
-17 -8 -20 -18 -4 -10 -16 -32 -29 -49 -18 -25 -26 -29 -40 -22 -20 12 -95 0
-77 -11 7 -4 9 -13 5 -20 -6 -10 -1 -12 18 -8 24 5 26 3 21 -18 -6 -22 -4 -24
27 -17 41 8 106 40 117 57 4 7 20 21 35 31 18 12 26 23 22 34 -3 8 -3 12 1 8
4 -4 14 2 21 13 18 26 35 82 22 75 -5 -4 -9 1 -9 9 0 9 5 16 10 16 6 0 10 9
10 20 0 11 -6 20 -14 20 -7 0 -16 10 -19 22 -10 36 -13 17 -9 -45 2 -31 8 -55
13 -51 5 3 9 -2 9 -10 0 -8 -4 -17 -9 -20 -11 -7 -24 45 -23 88 0 16 -4 38
-10 49 -7 14 -7 17 1 13 6 -4 11 -2 11 3 0 19 17 12 28 -11 7 -15 11 -18 11
-8 1 9 -6 26 -15 39 -13 18 -18 20 -25 10 -6 -10 -9 -9 -9 7 0 12 -10 25 -25
32 -55 25 -232 5 -221 -25 3 -6 -3 -11 -12 -10 -21 1 -68 -29 -76 -49 -4 -10
-1 -13 7 -9 6 4 -3 -5 -20 -20 -18 -14 -33 -32 -33 -40 0 -7 -6 -16 -12 -19
-7 -3 -2 -3 12 0 l25 5 -27 -17 c-21 -14 -30 -32 -43 -93 -10 -42 -24 -90 -31
-108 -8 -17 -14 -36 -14 -42 0 -6 -4 -11 -9 -11 -5 0 -24 -24 -42 -52 -18 -29
-39 -57 -46 -61 -8 -5 -12 -11 -9 -15 2 -4 -9 -7 -25 -7 -29 0 -37 9 -19 20 6
3 10 15 10 26 0 11 -4 18 -9 15 -14 -9 -23 26 -11 38 8 8 9 6 5 -6 -4 -10 -3
-18 3 -18 15 0 20 59 11 120 -4 30 -7 71 -7 90 0 33 1 33 9 10 6 -18 7 -12 4
24 -3 27 -5 56 -6 65 -1 9 -5 5 -9 -9 -8 -24 -8 -24 -9 -3 -1 12 7 30 17 40
26 25 34 53 22 68 -9 11 -4 12 22 8 39 -6 39 -6 58 -4 13 1 13 2 0 11 -12 7
-10 11 10 20 25 10 24 10 -3 5 -53 -11 -84 48 -73 136 8 62 -8 65 -23 4 -6
-25 -16 -45 -21 -45 -22 0 5 86 30 100 23 12 28 68 8 88 -9 9 -9 12 0 12 18 0
16 121 -3 146 -8 10 -15 14 -15 7 0 -7 -6 -10 -14 -7 -11 4 -13 -4 -10 -38 4
-42 4 -42 -6 -10 -6 17 -15 32 -20 32 -12 0 -50 -55 -50 -72 0 -8 -5 -19 -12
-26 -9 -9 -9 -12 1 -12 8 0 20 8 27 18 12 16 13 16 14 0 0 -9 -10 -25 -22 -35
-16 -13 -23 -28 -21 -51 1 -17 -2 -30 -7 -27 -10 6 -23 -116 -13 -124 5 -3 9
-19 8 -34 0 -24 -3 -27 -22 -22 -28 7 -55 -4 -46 -19 3 -6 2 -8 -2 -3 -5 4
-18 -1 -29 -10 l-21 -18 19 23 c12 14 16 26 10 31 -5 6 -16 0 -26 -14 -15 -20
-15 -25 -3 -41 15 -19 15 -19 30 1 14 18 15 17 9 -14 -4 -21 -3 -32 2 -28 23
14 37 -13 39 -70 1 -32 5 -74 9 -93 6 -35 6 -34 -9 5 -8 22 -12 29 -9 15 10
-49 15 -316 5 -295 -9 18 -10 17 -10 -7 -1 -16 4 -28 9 -28 6 0 10 -15 9 -32
-1 -32 -1 -32 -10 -8 -7 21 -8 20 -4 -9 3 -18 5 -37 5 -42 0 -30 143 -49 188
-25 37 19 87 74 77 83 -4 5 -10 2 -12 -4 -3 -7 -9 -13 -15 -13 -6 0 -3 9 7 20
13 14 22 17 29 10 7 -7 19 5 39 36 21 34 25 49 17 54 -9 6 -9 9 0 15 6 4 9 14
6 22 -3 8 -1 11 5 8 5 -4 7 -16 3 -27 -5 -16 -3 -20 8 -15 12 4 14 -2 12 -29
-1 -23 2 -37 12 -41 8 -3 11 -10 8 -15 -4 -6 1 -6 14 1 20 10 59 8 71 -5 3 -4
-1 -5 -11 -1 -10 4 -18 -1 -21 -12 -2 -10 1 -18 7 -18 31 1 47 -4 41 -12 -3
-5 3 -12 12 -14 10 -3 7 -4 -7 -3 -14 1 -31 5 -37 9 -8 5 -13 3 -13 -7 0 -9
15 -23 33 -33 44 -25 188 -24 235 0 38 20 43 31 8 17 -31 -12 -61 8 -45 30 9
13 10 13 6 -2 -8 -23 12 -27 51 -11 46 19 93 67 60 62 -25 -4 -46 12 -41 32 7
26 21 24 19 -3 -1 -16 5 -23 18 -23 24 0 33 -23 12 -31 -9 -3 -16 -10 -16 -16
0 -11 49 23 50 35 0 12 68 77 80 77 7 0 9 -8 5 -19 -8 -27 4 -51 23 -44 12 5
14 2 9 -11 -9 -22 32 -79 51 -71 7 2 11 1 8 -4 -8 -13 43 -32 99 -36 l50 -4
-45 8 c-25 5 -56 14 -70 20 -23 10 -22 11 10 6 19 -4 55 -8 80 -10 25 -2 32
-1 15 1 -54 8 -77 15 -71 21 3 3 2 11 -3 17 -6 7 -1 15 15 23 22 10 23 9 13
-9 -12 -22 1 -38 32 -38 9 0 31 -3 49 -7 19 -3 31 -2 28 3 -3 5 13 9 36 10
l41 1 -52 -16 c-53 -17 -89 -39 -43 -27 62 17 110 38 104 47 -3 5 2 9 10 9 27
0 97 71 94 94 -3 18 2 21 29 19 23 -2 36 3 47 19 9 12 16 18 16 13 0 -6 18
-11 40 -11 21 -1 39 1 38 5 -4 13 23 51 33 46 5 -4 6 -11 3 -17 -4 -6 -6 -48
-5 -93 2 -90 -10 -116 -52 -114 -20 1 -21 2 -2 6 11 3 24 9 29 14 13 12 17 89
5 89 -6 0 -5 8 1 19 5 11 10 30 9 43 -1 22 -2 22 -12 -4 -12 -31 -22 -35 -31
-13 -10 26 -36 17 -37 -12 -2 -24 -2 -25 -6 -5 -8 42 -20 22 -18 -30 2 -39 1
-42 -2 -10 -6 62 -18 51 -18 -18 0 -33 -4 -55 -9 -50 -4 4 -7 27 -4 49 2 23 0
41 -4 41 -13 0 -9 -95 4 -108 13 -13 39 -16 36 -4 0 4 8 27 19 52 19 42 21 43
19 15 0 -16 -10 -38 -21 -47 -13 -11 -15 -17 -7 -18 7 0 11 -4 8 -9 -9 -14 97
-22 111 -8 14 13 21 92 10 104 -4 3 -7 -15 -7 -40 0 -26 -4 -47 -8 -47 -14 0
-1 144 14 153 8 5 12 18 10 31 -2 13 2 36 9 50 10 19 11 25 2 21 -7 -4 -5 -1
3 7 9 7 26 29 39 48 l23 35 -24 -27 c-31 -37 -41 -36 -20 2 9 16 23 30 29 30
19 0 40 59 38 107 -1 28 2 40 9 36 6 -4 4 1 -4 12 -14 16 -13 17 5 11 16 -4
25 1 38 23 10 17 17 33 16 38 -4 22 4 28 34 28 28 0 32 3 35 30 4 36 17 45 57
44 29 -2 29 -2 -5 -8 -29 -5 -35 -10 -35 -33 -1 -14 -7 -33 -13 -41 -7 -8 -14
-35 -16 -59 -2 -24 -7 -54 -10 -66 -5 -13 -3 -22 3 -22 6 0 11 5 11 12 0 6 3
8 6 5 4 -4 2 -13 -5 -21 -11 -13 -29 -156 -19 -146 5 4 17 90 18 123 0 4 5 7
11 7 11 0 10 -8 -13 -119 -9 -39 -14 -49 -19 -35 -4 12 -7 5 -6 -21 0 -22 -1
-51 -2 -65 -2 -14 0 -41 4 -60 5 -28 4 -32 -5 -20 -10 13 -11 12 -6 -3 3 -10
7 -33 7 -50 1 -27 3 -25 9 13 5 25 7 70 4 100 -4 61 1 52 14 -27 5 -29 6 -50
2 -48 -5 3 -10 -16 -12 -41 -3 -37 1 -49 15 -59 11 -8 25 -11 32 -9 7 3 16 1
20 -5 9 -16 78 -13 101 4 17 13 17 14 2 15 -9 0 -20 -4 -23 -10 -3 -5 -23 -10
-43 -9 l-37 1 33 8 c52 12 46 17 -17 12 -32 -2 -61 0 -64 4 -3 5 39 8 94 7
l99 -2 31 39 c17 21 30 41 30 44 -1 4 7 16 18 28 25 27 66 109 62 127 -1 8 2
10 11 5 8 -5 6 2 -4 19 -10 14 -18 32 -18 39 0 9 3 9 10 -2 10 -17 9 -17 35
32 10 21 21 36 23 34 2 -2 -6 -22 -17 -45 -12 -22 -21 -46 -20 -53 0 -7 13 14
29 47 15 33 24 62 19 65 -4 3 -4 12 1 20 8 13 10 13 10 1 0 -8 3 -12 6 -8 3 3
2 13 -3 22 -9 15 -11 14 -25 -7 -8 -13 -18 -33 -22 -45 -4 -11 -14 -27 -24
-35 -15 -12 -15 -10 1 15 10 16 16 37 13 50 -3 12 -3 16 0 10 4 -9 14 -3 31
17 14 17 29 28 34 24 14 -9 10 19 -7 41 -15 20 -16 20 -40 -9 -14 -16 -23 -35
-20 -42 3 -8 -1 -14 -10 -14 -11 0 -12 -4 -4 -19 13 -25 13 -31 0 -31 -5 0
-10 8 -10 17 0 9 -19 -21 -42 -67 -24 -47 -53 -99 -65 -117 -13 -17 -23 -34
-23 -37 0 -3 -11 -17 -25 -32 -14 -15 -23 -29 -21 -31 5 -6 63 49 69 67 4 12
6 11 6 -3 1 -20 -21 -47 -38 -47 -6 0 -11 -4 -11 -10 0 -14 23 -13 38 2 6 6
15 8 19 4 8 -7 -18 -31 -41 -39 -8 -3 -7 -9 2 -19 9 -11 10 -18 2 -23 -5 -3
-10 0 -10 8 0 11 -11 12 -52 5 -29 -4 -66 -9 -83 -10 -16 -1 -33 -7 -37 -13
-5 -6 -8 -3 -8 8 0 10 8 17 19 17 21 0 31 26 31 87 0 23 4 44 9 47 4 3 7 -19
6 -49 -2 -54 -1 -55 30 -61 41 -7 80 -2 80 11 0 5 -17 10 -38 9 -30 0 -37 3
-32 15 9 22 22 104 29 171 3 40 2 48 -5 30 -7 -20 -8 -18 -4 15 3 22 5 57 5
78 0 21 4 36 9 33 5 -4 7 8 5 26 -4 26 -2 30 7 18 9 -13 10 -12 5 3 -3 10 -6
28 -7 40 0 12 -7 0 -14 -28 l-13 -50 5 55 c2 30 6 61 9 68 2 7 -1 22 -6 33 -6
10 -8 19 -5 19 3 0 12 -15 20 -32 12 -28 14 -29 15 -10 0 12 -9 30 -20 40 -11
9 -32 27 -46 40 -43 38 -128 24 -122 -21 2 -18 -3 -23 -27 -25 -23 -2 -39 -15
-70 -59 -22 -31 -39 -60 -37 -66 2 -5 -6 -16 -17 -24 -13 -8 -18 -20 -14 -30
4 -11 3 -14 -5 -9 -7 4 -12 2 -12 -3 0 -29 49 8 51 37 0 11 2 13 6 4 3 -7 -3
-24 -12 -38 -9 -14 -14 -32 -11 -39 2 -7 -2 -19 -10 -26 -18 -15 -32 -3 -16
13 15 15 15 28 2 28 -6 0 -14 -10 -19 -22 -9 -23 -9 -23 -21 5 -13 28 -30 36
-30 15 0 -6 -3 -8 -7 -5 -4 4 7 22 24 40 18 17 33 39 35 47 2 8 11 23 21 34
15 18 22 40 15 49 -2 2 3 11 11 21 15 18 9 32 -9 21 -7 -4 -9 -16 -6 -28 3
-14 2 -17 -5 -10 -20 20 -5 48 24 48 16 -1 27 5 27 12 0 7 -9 13 -20 13 -12 0
-20 7 -20 18 0 14 2 15 10 2 8 -12 10 -12 10 -1 0 11 3 11 16 0 13 -11 14 -9
9 17 -5 21 -4 25 3 15 8 -11 15 0 28 45 10 32 17 68 16 79 -1 10 4 25 10 32 7
9 7 17 1 26 -6 6 -9 23 -7 37 6 42 -8 203 -16 190 -5 -8 -9 -5 -13 9 -3 12 -1
19 4 15 5 -3 9 -3 9 0 0 3 -15 13 -32 21 -24 12 -28 12 -15 2z m33 -99 c-16
-11 -26 5 -19 30 l6 23 11 -23 c9 -16 9 -25 2 -30z m-26 35 c0 -5 -10 -24 -22
-43 -11 -19 -25 -48 -29 -65 -8 -29 -8 -29 -5 5 3 41 32 110 46 110 6 0 10 -3
10 -7z m-1610 -72 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z
m1543 -106 c-3 -11 -7 -40 -8 -65 -1 -25 -5 -63 -8 -85 -6 -34 -6 -36 4 -15 6
14 11 53 12 87 1 33 6 59 11 55 5 -3 7 -1 3 4 -3 5 1 34 9 63 13 53 24 76 24
52 0 -7 4 -10 8 -7 5 3 7 -14 4 -37 -2 -23 -4 -64 -4 -92 0 -39 -2 -45 -8 -25
-8 25 -8 25 -9 2 -1 -13 4 -32 9 -42 7 -13 7 -21 0 -25 -5 -3 -10 0 -11 7 -1
15 -28 -73 -35 -112 -2 -14 0 -11 6 8 6 17 13 32 16 32 5 0 -5 -61 -11 -68 -7
-8 -33 12 -29 22 3 7 -2 26 -11 42 -17 33 -22 100 -5 74 7 -11 10 -7 12 20 1
19 3 51 5 70 2 19 4 62 4 95 l1 60 9 -50 c4 -27 6 -59 2 -70z m-117 67 c-18
-16 -18 -16 -6 6 6 13 14 21 18 18 3 -4 -2 -14 -12 -24z m94 -63 c0 -5 -4 -9
-10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-93 -11 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1404 -12 c19 -6 23 -42 7 -66
-8 -12 -10 -10 -11 10 0 19 -2 21 -7 8 -10 -26 -29 -8 -37 35 -7 36 -6 38 11
29 11 -6 27 -13 37 -16z m1476 -10 c0 -6 -4 -20 -8 -31 -7 -16 -9 -14 -12 16
-4 27 -1 35 8 32 7 -3 13 -10 12 -17z m156 4 c6 -10 -13 -137 -25 -159 -5 -9
-7 -3 -7 15 0 16 -3 54 -7 84 -7 60 -2 58 10 -5 l7 -40 8 40 c4 22 3 44 -2 50
-5 5 -9 13 -9 17 0 11 18 10 25 -2z m-225 -27 c0 -21 -3 -24 -9 -14 -5 8 -7
20 -4 28 8 21 13 15 13 -14z m21 -36 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3
-3 0 -11 -7 -18z m-36 3 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
3 0 8 -4 11 -10z m62 -64 c-1 -18 -8 -39 -15 -46 -11 -10 -12 -5 -6 27 11 62
24 74 21 19z m-77 -12 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6
0 10 -7 10 -16z m-1483 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m1672 -9 c1 8 3 12 6 10 2 -3 1 -16 -2 -29 -6 -22 -7 -23 -14 -4 -4 10
-7 28 -7 39 0 16 2 15 8 -5 4 -14 8 -19 9 -11z m-1611 -14 c-1 -14 -6 -25 -11
-25 -4 0 -6 -4 -2 -9 9 -15 -15 -24 -30 -11 -13 11 -12 16 6 41 25 36 39 37
37 4z m74 -25 c3 -16 1 -30 -4 -30 -4 0 -8 14 -8 30 0 17 2 30 4 30 2 0 6 -13
8 -30z m1305 -42 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z
m-1330 30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m531 -5 c-15
-2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z m487 -3 c-27 -12 -43 -12
-25 0 8 5 22 9 30 9 10 0 8 -3 -5 -9z m125 -4 c0 -18 -6 -22 -50 -30 -26 -4
-30 -3 -15 3 19 9 19 10 -2 10 -14 1 -23 7 -23 16 0 11 12 15 45 15 33 0 45
-4 45 -14z m-553 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-604 -30 c-22 -16 -40 -18 -48 -4 -11 18 3 27 35 22 28 -4 29 -5 13 -18z
m514 20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m58 -3 c3 -6
-10 -13 -30 -17 -19 -3 -38 -10 -41 -15 -3 -5 -10 -9 -15 -9 -6 0 -8 4 -5 8 3
5 -8 9 -24 9 -17 0 -27 -5 -23 -10 3 -6 1 -7 -6 -3 -15 10 -3 29 15 22 7 -3
16 -1 19 5 4 5 13 6 22 1 20 -12 70 -3 51 9 -9 6 -7 9 9 9 12 0 24 -4 28 -9z
m947 -93 c-3 -10 -9 -18 -14 -18 -5 0 -6 5 -3 10 3 6 1 10 -4 10 -6 0 -11 8
-11 18 0 14 2 15 10 2 5 -8 12 -12 15 -8 3 3 -1 13 -10 22 -13 13 -14 18 -3
30 11 12 14 9 18 -18 3 -17 4 -39 2 -48z m318 67 c0 -8 -4 -15 -10 -15 -5 0
-7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-1455 -11 c21 -22 15 -48 -13 -52
-29 -4 -28 12 1 27 12 6 14 9 5 7 -12 -4 -15 -1 -11 11 4 11 2 14 -8 11 -8 -3
-13 -11 -11 -17 1 -6 -5 -11 -13 -11 -20 0 -19 10 3 26 22 17 27 17 47 -2z
m829 -53 c-4 -3 -9 0 -11 7 -6 17 -33 15 -33 -3 0 -8 -4 -15 -10 -15 -16 0
-12 18 12 45 l21 24 14 -26 c7 -14 10 -29 7 -32z m35 4 c0 -5 -6 1 -14 15 -8
14 -14 30 -14 35 0 6 6 -1 14 -15 8 -14 14 -29 14 -35z m538 48 c-9 -2 -14 -9
-11 -15 4 -7 2 -8 -5 -4 -15 10 -4 26 17 25 15 -1 15 -2 -1 -6z m-1857 -13 c0
-5 -10 -10 -22 -9 -22 0 -22 1 -3 9 11 5 21 9 23 9 1 1 2 -3 2 -9z m1930 -32
l-1 -43 -14 34 c-17 38 -19 53 -5 31 8 -13 10 -12 10 3 0 9 2 17 5 17 3 0 5
-19 5 -42z m-1255 19 c13 -10 16 -21 11 -40 -9 -39 -26 -33 -26 8 0 33 -2 35
-27 30 -23 -4 -25 -4 -8 4 27 13 29 13 50 -2z m865 -33 c0 -13 -4 -24 -10 -24
-5 0 -10 14 -10 31 0 17 4 28 10 24 6 -3 10 -17 10 -31z m-580 -32 c0 -15 -5
-22 -12 -19 -7 2 -12 13 -11 23 1 10 -3 22 -9 25 -6 4 -7 12 -3 18 10 17 34
-17 35 -47z m520 -22 c0 -38 -4 -72 -10 -75 -6 -4 -10 23 -10 75 0 52 4 79 10
75 6 -3 10 -37 10 -75z m230 59 c-7 -11 -14 -19 -16 -16 -7 7 7 37 17 37 6 0
5 -9 -1 -21z m-99 -22 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7
-23z m-1521 7 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m330
10 c10 -4 8 -9 -10 -18 -27 -14 -40 -12 -40 8 0 13 24 18 50 10z m-440 -8 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m367
-18 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1559 -40 c-15 -10
-17 -5 -9 33 l7 34 6 -30 c3 -16 1 -33 -4 -37z m-669 30 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-1290 -20 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13
2 -7 2 -19 0 -25z m1883 -17 c-10 -26 -10 -33 4 -46 19 -20 20 -35 2 -100
l-14 -50 -1 48 c-1 30 3 46 9 42 6 -4 10 5 10 20 0 14 -4 24 -9 21 -13 -9 -23
40 -15 80 4 24 10 32 17 25 7 -7 6 -20 -3 -40z m-1460 29 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m843 -3 c2 -6 -1 -18 -8
-24 -10 -10 -14 -9 -19 3 -7 17 0 34 14 34 4 0 10 -6 13 -13z m307 -3 c0 -8
-4 -12 -10 -9 -5 3 -10 1 -10 -6 0 -7 -3 -10 -6 -6 -9 9 3 37 16 37 5 0 10 -7
10 -16z m63 3 c7 -19 -8 -43 -17 -28 -4 6 -12 9 -17 6 -13 -9 -11 9 3 23 16
16 25 15 31 -1z m-1597 -9 c4 -7 3 -8 -4 -4 -8 5 -12 -1 -13 -16 0 -13 -3 -17
-6 -10 -5 13 2 42 11 42 3 0 8 -5 12 -12z m1433 -25 c0 -16 -4 -46 -8 -68 -8
-37 -9 -35 -10 28 -1 39 3 67 9 67 6 0 10 -12 9 -27z m-429 7 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1200 -15 c-7 -8 -17
-15 -23 -15 -5 0 -2 9 8 20 21 23 35 19 15 -5z m-1830 6 c0 -6 -4 -12 -8 -15
-5 -3 -9 1 -9 9 0 8 4 15 9 15 4 0 8 -4 8 -9z m324 -13 c-1 -13 -3 -27 -3 -31
-1 -5 -5 -5 -11 -2 -15 9 -12 55 3 55 8 0 13 -9 11 -22z m242 7 c4 -8 12 -15
19 -15 7 0 15 -10 19 -22 7 -29 -25 -123 -36 -105 -13 21 -9 50 6 44 9 -4 16
3 20 16 8 32 7 37 -6 35 -7 -2 -17 7 -23 20 -5 12 -13 22 -17 22 -5 0 -8 5 -8
10 0 16 20 12 26 -5z m-539 -32 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m332 -6 c1 -5 -3 -5 -9 -2 -5 3 -10 16 -9 28 0 21 1 21 9 2 5 -11
9 -24 9 -28z m-287 11 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3
-1 -11 -7 -17z m1493 -90 c4 -114 -3 -200 -16 -192 -9 5 -5 163 5 229 4 25 3
33 -2 21 -8 -18 -9 -18 -15 -3 -3 9 -2 17 3 17 6 0 10 10 10 22 0 12 3 19 6
15 3 -3 7 -52 9 -109z m-1628 70 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m-317 0 c0 -13 -5 -30 -10 -38 -7 -11 -10 -5 -10 23 0 20 5 37 10
37 6 0 10 -10 10 -22z m56 5 c7 -10 14 -27 15 -38 1 -11 5 -2 8 20 6 32 9 19
15 -68 5 -59 6 -111 2 -114 -3 -4 -6 21 -6 54 0 45 -4 62 -15 67 -8 3 -15 16
-15 28 0 13 -7 34 -16 46 -18 26 -7 30 12 5z m612 -17 c-8 -6 -21 -24 -29 -40
-19 -40 -60 -53 -102 -32 -37 18 -21 22 18 4 23 -10 29 -8 55 17 17 16 28 35
25 43 -2 7 0 10 5 7 6 -3 10 1 10 9 0 10 6 13 16 9 15 -5 15 -8 2 -17z m864
-3 c-3 -16 -7 -22 -10 -15 -4 13 4 42 11 42 2 0 2 -12 -1 -27z m-513 10 c0 -5
-4 -17 -9 -28 -8 -18 -9 -17 -9 8 -1 15 4 27 9 27 6 0 10 -3 9 -7z m438 -15
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-517 -6 c0 -12 -3 -12
-15 -2 -8 7 -15 13 -15 15 0 1 7 2 15 2 8 0 15 -7 15 -15z m-633 -31 c-1 -17
-5 -27 -9 -23 -7 7 0 52 8 52 2 0 2 -13 1 -29z m1280 -7 c-9 -10 -38 16 -31
28 4 6 13 4 22 -6 9 -8 13 -19 9 -22z m556 -2 c-3 -12 -9 -22 -14 -22 -12 0
-11 7 3 30 15 24 19 21 11 -8z m-2149 -1 c3 -5 -1 -11 -9 -15 -9 -3 -15 0 -15
9 0 16 16 20 24 6z m1436 -6 c11 -13 10 -14 -4 -9 -12 5 -15 2 -10 -10 3 -9 9
-16 13 -16 3 0 6 -11 6 -24 0 -13 -4 -22 -9 -19 -4 3 -13 -1 -18 -8 -10 -14
-7 -2 8 36 3 8 1 15 -5 15 -6 0 -11 -7 -11 -15 0 -8 -5 -15 -11 -15 -6 0 -9 3
-7 8 3 4 10 22 17 40 15 37 15 37 31 17z m67 0 c0 -8 -4 -12 -9 -9 -4 3 -8 9
-8 15 0 5 4 9 8 9 5 0 9 -7 9 -15z m53 5 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m59 -26 c-15 -18 -22 -13 -13 10 3 9 10 13
16 10 5 -3 4 -12 -3 -20z m-988 -9 c-24 -14 -46 -23 -48 -21 -5 5 67 45 82 45
6 1 -10 -11 -34 -24z m1206 -37 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2
-33 0 -45z m70 15 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m-1920 -55 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0 -60z m869 56
c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z
m131 -11 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-663 19 c3 7
2 0 -1 -16 -5 -23 -3 -27 8 -21 8 5 11 5 7 0 -4 -4 -3 -14 3 -21 7 -8 7 -14
-1 -19 -14 -9 -30 3 -25 18 7 17 -15 46 -22 30 -2 -7 -8 -13 -13 -13 -4 0 -3
11 3 24 8 17 15 22 23 15 8 -7 14 -6 18 3z m-297 -24 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m857 -3 c-3 -7 -12 -16 -19 -19 -11 -4 -12 0 -3
19 11 25 31 25 22 0z m661 -4 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15
11 -17z m-1549 -171 l-15 -35 1 60 c0 48 2 54 8 30 6 -22 8 -8 11 55 l3 85 3
-80 c3 -56 -1 -92 -11 -115z m394 160 c0 -24 27 -34 33 -12 3 10 5 6 6 -10 1
-20 -3 -26 -14 -22 -8 4 -15 1 -15 -5 0 -6 5 -11 10 -11 6 0 10 -4 10 -9 0 -4
-12 -6 -26 -3 -24 4 -25 7 -19 45 5 24 3 37 -2 34 -5 -3 -10 0 -10 7 0 8 6 12
14 9 7 -3 13 -13 13 -23z m1726 -19 c-7 -14 -11 -32 -8 -40 2 -8 0 -11 -5 -8
-6 4 -9 23 -7 44 2 21 3 42 3 47 1 5 8 3 16 -4 12 -10 12 -16 1 -39z m-1117
-51 c0 -23 -15 7 -22 45 l-6 30 14 -30 c8 -16 14 -37 14 -45z m911 54 c0 -9
-5 -14 -12 -12 -18 6 -21 28 -4 28 9 0 16 -7 16 -16z m225 -37 c-8 -20 -17
-37 -18 -37 -8 0 6 66 16 79 18 21 19 -2 2 -42z m-296 -23 c4 3 5 -12 2 -32
l-5 -37 -7 40 c-4 22 -7 51 -7 65 1 18 2 15 5 -8 2 -18 8 -30 12 -28z m-979
34 c-1 -20 -26 -49 -35 -40 -4 4 -4 -4 -1 -18 5 -17 4 -21 -2 -11 -8 11 -14
10 -36 -4 -23 -15 -26 -15 -20 -2 3 9 15 19 26 23 14 4 17 8 8 14 -8 5 -4 14
16 29 30 24 44 27 44 9z m-617 -18 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2
0 2 -9 -1 -20z m684 -22 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m416 20 c-4 -7 -6 -21 -4 -31 2 -15 -2 -17 -17 -13 -17 6 -19 3 -15 -13 4 -16
1 -19 -16 -14 -25 6 -27 -5 -4 -22 11 -8 13 -14 4 -19 -6 -4 -14 0 -17 8 -3 9
-12 16 -19 16 -7 0 8 22 34 49 44 46 71 65 54 39z m754 -43 c3 -18 -1 -25 -13
-25 -11 0 -14 5 -10 16 3 9 6 23 6 31 0 24 14 6 17 -22z m-692 14 c11 -17 2
-18 -19 -3 -17 12 -17 14 -4 14 9 0 20 -5 23 -11z m-1140 -29 c0 -16 -5 -24
-14 -22 -8 2 -11 -3 -8 -12 4 -10 1 -13 -8 -10 -17 7 -8 34 11 34 8 0 14 5 14
10 0 14 -33 13 -48 -2 -9 -9 -12 -9 -12 0 0 14 31 32 50 29 9 -1 15 -13 15
-27z m-155 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m370 -15 c10 -12 16 -13 26 -4 10 8 14 8 14 0 0 -10 -7 -13 -26 -10
-4 0 -10 -3 -13 -8 -3 -5 -17 2 -30 14 -23 22 -23 23 -4 23 11 0 26 -7 33 -15z
m415 6 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m68 2 c-19 -7
-15 -21 8 -28 11 -4 18 -9 15 -12 -3 -4 -1 -12 5 -20 6 -7 8 -13 3 -13 -5 0
-2 -5 6 -10 9 -6 10 -10 3 -10 -6 0 -18 8 -25 18 -15 20 -48 28 -48 11 0 -7
-2 -10 -5 -7 -3 3 -3 13 1 22 5 13 9 14 20 5 17 -14 19 2 3 18 -13 13 -4 33
14 32 9 0 9 -2 0 -6z m541 -9 c3 -8 2 -12 -4 -9 -6 3 -10 1 -10 -6 0 -7 -3
-10 -6 -6 -7 6 2 37 10 37 2 0 7 -7 10 -16z m-1294 -25 c0 -5 -4 -9 -10 -9 -5
0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m1140 18 c0 -2 -13 -19 -30
-37 -16 -18 -30 -29 -30 -23 0 5 9 18 20 28 11 10 17 22 14 26 -3 5 2 9 10 9
9 0 16 -1 16 -3z m-690 -17 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m393 -7 c-3 -7 -9 -10 -14 -7 -14 8 -11 21 5 21 8 0 12 -6
9 -14z m-983 -14 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6
0 10 -9 10 -21z m-47 -41 c4 1 24 -5 44 -13 32 -14 38 -14 53 0 9 8 29 15 43
15 28 0 36 -13 15 -26 -7 -4 -8 -3 -4 4 5 8 -1 11 -18 10 -24 -2 -38 -18 -16
-18 6 0 10 -5 10 -11 0 -8 -4 -8 -14 1 -11 8 -20 9 -33 1 -17 -10 -17 -10 1
-11 11 0 17 -2 15 -4 -3 -3 -27 -1 -54 3 l-50 9 40 1 c33 1 37 3 22 12 -10 6
-30 8 -45 4 -27 -6 -28 -4 -29 37 -1 37 0 38 6 13 3 -16 10 -29 14 -27z m1204
46 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m833 -4 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1950 -15
c0 -8 -4 -15 -9 -15 -5 0 -11 7 -15 15 -3 9 0 15 9 15 8 0 15 -7 15 -15z m397
-7 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m733 2 c0 -5 -7 -10
-15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m587 -12 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1845 -30 c-8 -8 -9 -4 -5 13
4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m978 28 c0 -3 -4 -8 -10 -11 -5 -3 -10
-1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-350 -41 c44 -9 44 -9 15 -16 -52
-12 -129 -9 -160 7 l-30 14 28 -5 c16 -3 26 -2 24 2 -6 10 65 9 123 -2z m947
-21 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5421 3846 c9 -10 59 -14 54 -5 -4 5 -18 9 -32 9 -15 0 -24 -2 -22
-4z"/>
<path d="M6173 3440 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M4611 3394 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4748 3173 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M3585 4320 c3 -6 -6 -11 -22 -12 -33 -1 -36 -2 -86 -12 -24 -4 -35
-11 -31 -18 4 -7 3 -8 -4 -4 -17 10 -52 -12 -46 -29 4 -10 0 -15 -16 -15 -14
0 -19 4 -14 12 4 6 -9 -1 -30 -17 -37 -28 -37 -29 -15 -40 16 -9 18 -14 8 -18
-10 -4 -10 -6 -1 -6 19 -1 14 -19 -9 -31 -19 -11 -20 -9 -17 16 2 15 -2 29 -8
31 -16 5 -76 -66 -70 -83 3 -7 1 -16 -4 -19 -5 -3 -7 -13 -4 -23 5 -16 5 -16
-8 0 -13 15 -17 10 -46 -46 -18 -35 -32 -70 -32 -79 0 -9 -4 -18 -9 -22 -5 -3
-15 -31 -22 -62 -6 -32 -14 -64 -17 -73 -3 -10 -2 -12 6 -5 6 6 12 21 15 35
l4 25 2 -26 c0 -15 8 -32 16 -39 8 -7 15 -18 15 -25 0 -7 -7 -15 -15 -19 -17
-6 -22 -111 -5 -121 6 -3 9 -16 9 -28 -1 -18 -2 -19 -6 -4 -5 18 -6 18 -19 -2
-14 -19 -13 -28 3 -96 3 -16 2 -17 -6 -5 -6 8 -11 25 -11 38 0 12 -5 22 -11
22 -6 0 -8 -8 -5 -17 3 -10 8 -30 12 -45 3 -16 10 -25 15 -22 5 3 9 3 9 0 0
-2 3 -13 6 -23 5 -15 4 -15 -5 -3 -9 13 -11 13 -11 -2 0 -10 9 -22 20 -28 12
-6 20 -21 20 -36 0 -27 121 -157 143 -153 23 5 97 -13 97 -23 0 -6 -8 -7 -17
-3 -38 15 -56 15 -23 0 45 -21 190 -33 190 -16 0 15 -10 18 -85 27 l-60 7 39
9 c21 4 41 6 44 3 3 -3 -3 -6 -14 -6 -10 -1 6 -5 36 -9 30 -5 56 -13 58 -19 2
-6 8 -11 14 -11 5 0 28 -20 51 -45 24 -28 46 -44 55 -40 8 3 16 -2 19 -13 6
-26 65 -52 111 -51 l37 2 -40 6 c-22 4 -49 13 -60 20 l-20 13 20 -8 c23 -10
96 -10 140 0 23 4 22 3 -5 -5 -19 -7 -29 -14 -23 -16 18 -7 83 17 83 30 0 6
10 22 23 35 20 23 21 25 2 18 -36 -14 -55 -15 -55 -4 0 5 7 8 15 5 8 -4 17 -2
20 2 10 17 64 31 71 19 5 -7 2 -8 -5 -4 -8 5 -11 3 -9 -6 2 -8 -10 -27 -27
-42 l-30 -28 30 20 c30 20 91 82 68 68 -15 -9 -17 3 -3 24 9 13 10 13 10 0 0
-10 7 -7 20 10 22 29 26 45 8 34 -7 -4 -5 1 4 12 9 10 19 33 22 49 5 25 5 27
-4 10 -9 -18 -10 -18 -7 6 3 18 -1 25 -15 26 -11 1 -16 -3 -12 -9 4 -6 1 -9
-6 -6 -8 2 -36 -20 -64 -49 -38 -41 -59 -55 -88 -59 -21 -4 -41 -11 -44 -16
-3 -5 -22 1 -41 12 -25 15 -38 18 -43 10 -4 -6 -6 5 -6 25 1 24 8 40 20 47 15
8 16 12 5 19 -8 5 -9 9 -4 9 6 0 16 -4 24 -9 11 -7 8 -13 -13 -26 -36 -24 -26
-30 13 -8 17 10 29 24 26 31 -3 8 0 10 6 6 12 -7 63 45 110 113 33 48 35 55
12 46 -11 -4 -8 2 8 18 13 13 27 23 31 22 5 -2 8 1 8 6 0 5 3 18 6 28 3 10 2
14 -3 8 -10 -12 -63 -16 -63 -5 0 4 6 13 14 19 11 9 15 9 18 0 9 -26 30 3 24
33 -3 18 -2 27 4 23 6 -3 10 -18 11 -33 l1 -27 13 30 c8 17 11 45 8 66 -3 24
0 40 9 50 17 17 30 86 18 94 -11 7 -2 34 18 57 11 13 12 21 3 31 -6 8 -8 17
-4 20 3 4 1 7 -6 7 -7 0 -9 5 -4 13 12 20 14 49 3 62 -5 6 -12 27 -14 46 -8
59 -18 93 -39 129 -32 54 -68 89 -85 82 -11 -4 -14 -1 -9 11 3 9 1 19 -5 23
-6 4 -8 3 -4 -4 3 -6 2 -13 -4 -17 -6 -3 -10 3 -10 15 0 16 -4 20 -16 16 -9
-3 -26 -8 -37 -10 -12 -2 -16 -1 -9 1 6 3 12 9 12 15 0 5 7 8 15 4 34 -13 12
11 -25 27 -54 23 -75 21 -25 -3 l40 -19 -35 6 c-44 7 -72 21 -65 33 3 4 -3 6
-14 3 -10 -3 -22 0 -25 6 -9 14 -81 14 -107 1 -13 -7 -19 -7 -19 0 0 5 -5 10
-11 10 -5 0 -7 -4 -4 -10z m-65 -30 c0 -5 -10 -10 -22 -9 -22 0 -22 1 -3 9 11
5 21 9 23 9 1 1 2 -3 2 -9z m135 0 c-3 -5 -1 -10 6 -10 7 0 10 -3 6 -6 -9 -9
-28 6 -22 17 4 5 8 9 11 9 3 0 2 -4 -1 -10z m-70 -27 c12 -8 14 -13 6 -13 -11
0 -11 -3 -1 -15 7 -8 23 -15 36 -15 13 0 24 -4 24 -10 0 -5 18 -15 40 -21 26
-8 57 -30 88 -63 26 -28 50 -50 54 -49 4 2 5 -4 2 -11 -3 -8 1 -25 10 -38 21
-32 55 -172 50 -201 -4 -17 -2 -15 8 6 12 27 13 24 13 -37 0 -36 -4 -68 -8
-72 -4 -4 -7 -21 -7 -38 0 -17 -7 -60 -14 -96 -9 -39 -10 -69 -5 -74 12 -13
12 -36 0 -36 -5 0 -12 6 -14 13 -2 6 -3 -1 -1 -17 3 -24 2 -27 -11 -16 -8 7
-15 10 -15 7 0 -3 -3 -14 -6 -24 -5 -15 -4 -15 5 -3 6 8 11 11 11 5 0 -14 -16
-37 -23 -33 -7 4 -41 -41 -50 -65 -4 -10 -11 -16 -15 -13 -5 3 -21 -4 -38 -15
-32 -21 -69 -25 -79 -9 -4 6 -11 8 -16 5 -5 -3 -6 1 -3 9 6 15 -5 20 -33 17
-7 -1 -13 5 -13 13 0 9 -13 22 -30 31 -21 11 -30 12 -30 4 0 -8 -17 -10 -55
-6 -44 4 -57 2 -67 -11 -15 -20 -43 -29 -32 -11 5 9 2 10 -9 6 -9 -3 -16 -3
-16 1 3 20 -2 24 -14 8 -12 -16 -13 -16 -13 3 0 12 4 21 8 21 4 0 0 13 -10 29
-9 16 -21 27 -26 24 -5 -4 -7 1 -3 11 4 10 3 15 -3 11 -6 -3 -11 0 -11 7 -1 7
-3 22 -5 33 -2 11 -6 39 -10 62 -4 24 -10 41 -13 38 -7 -4 -4 100 5 170 2 22
6 60 9 85 5 58 15 91 25 85 5 -3 11 8 15 25 4 16 17 44 29 62 12 19 21 39 18
46 -2 7 3 12 11 12 7 0 21 8 31 18 29 33 121 73 187 81 35 5 51 9 36 10 -16 0
-26 5 -22 11 3 6 -4 10 -16 10 -22 0 -22 0 -3 14 18 14 18 15 -9 21 -15 4 -27
11 -27 16 0 10 27 1 55 -18z m230 -3 c-27 -12 -43 -12 -25 0 8 5 22 9 30 9 10
0 8 -3 -5 -9z m-332 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m191 -14 c-3 -5 -17 -8 -32 -8 -24 2 -24 2 -4 6 12 2 20 9 17 14 -3 5 1 6 9 3
9 -3 13 -10 10 -15z m43 15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-196 -24 c5 0 9 -4 9 -9 0 -4 -11 -6 -25 -3 -14 2 -25 9 -25 14 0 6 7 7
16 4 9 -3 20 -6 25 -6z m256 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-401 -14 c-10 -7 -24 -10 -32 -7 -23 9 3 32 28 25 20 -5 20 -6 4
-18z m78 0 c-19 -15 -17 -15 31 1 20 7 20 6 1 -9 -11 -9 -28 -16 -39 -16 -10
0 -15 -4 -12 -10 3 -5 2 -10 -3 -10 -16 0 -23 31 -11 46 6 8 21 14 32 14 21 0
21 0 1 -16z m306 6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z m60 -26 c0 -8 -4 -14 -10 -14 -5 0 -10 5 -10 11 0 5 -4 8 -9 5
-5 -4 -7 3 -4 14 4 16 8 18 19 9 8 -6 14 -18 14 -25z m-106 17 c4 -5 -3 -7
-14 -4 -23 6 -26 13 -6 13 8 0 17 -4 20 -9z m122 -40 c-4 -27 -2 -41 7 -44 6
-3 3 -6 -9 -6 -11 -1 -36 13 -55 30 -19 18 -27 28 -19 24 8 -4 25 -17 38 -28
19 -18 22 -18 23 -4 1 9 3 22 4 27 1 6 3 17 4 25 0 8 4 15 7 15 4 0 4 -18 0
-39z m-79 23 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m174 -17
c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-541 -28 c0 -4 -7
-10 -16 -13 -9 -3 -12 -2 -9 5 4 5 1 17 -7 26 -12 15 -11 15 10 2 12 -8 22
-17 22 -20z m620 -3 c0 -5 -7 -3 -15 4 -8 7 -12 17 -9 22 7 10 24 -9 24 -26z
m-55 3 c-4 -5 -17 -9 -30 -9 -13 0 -25 -4 -27 -9 -1 -5 -14 -3 -28 4 l-25 14
25 2 c14 1 32 1 42 0 9 -1 19 3 23 9 3 6 11 8 16 4 5 -3 7 -10 4 -15z m-700
-29 c-10 -11 -20 -18 -23 -15 -3 3 3 14 13 25 10 11 20 18 23 15 3 -3 -3 -14
-13 -25z m781 -25 c7 -19 14 -42 13 -52 0 -15 -2 -14 -9 3 -10 25 -30 41 -30
25 0 -6 -5 -11 -11 -11 -6 0 -9 8 -7 18 2 9 10 16 17 14 8 -2 10 4 6 18 -11
33 5 22 21 -15z m-736 11 c0 -8 -10 -13 -24 -13 -20 0 -22 2 -13 13 16 17 37
18 37 0z m596 -22 c15 -4 20 -11 17 -25 -4 -15 -2 -18 13 -13 17 6 18 5 3 -7
-9 -8 -19 -21 -22 -29 -3 -8 -6 -3 -6 13 -1 15 -10 36 -21 47 -21 21 -18 23
16 14z m-589 -20 c3 -9 -2 -23 -13 -32 -16 -16 -17 -16 -5 0 8 11 9 18 1 23
-5 3 -10 10 -10 16 0 15 20 10 27 -7z m583 -64 c9 -31 19 -49 22 -40 3 8 3 6
0 -5 -2 -11 -4 -33 -4 -50 0 -30 0 -30 -10 5 -5 19 -13 49 -19 66 -5 17 -9 38
-9 47 0 9 -8 21 -17 27 -17 10 -17 11 1 8 13 -2 24 -20 36 -58z m-670 6 c0 -6
-7 -1 -15 10 -8 10 -15 23 -15 28 0 6 7 1 15 -10 8 -10 15 -23 15 -28z m815 5
c2 -12 0 -19 -6 -16 -5 4 -7 11 -4 16 4 5 1 9 -4 9 -6 0 -11 8 -11 17 0 23 19
3 25 -26z m-841 -17 c4 -15 2 -32 -3 -37 -6 -6 -11 -15 -11 -21 0 -6 5 -5 12
2 18 18 30 14 23 -7 -3 -11 1 -36 10 -56 20 -50 19 -56 -5 -20 -11 17 -20 38
-20 48 0 9 -4 17 -10 17 -5 0 -3 -19 5 -41 15 -41 21 -98 9 -86 -3 3 -8 23
-11 44 -3 21 -10 50 -17 64 -11 22 -11 32 0 58 8 18 9 33 3 36 -12 8 -12 25 0
25 5 0 12 -12 15 -26z m47 -11 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0
-13 -6 -23z m739 -19 c0 -19 3 -34 8 -34 4 0 12 -8 19 -18 9 -15 7 -21 -8 -30
-11 -7 -18 -9 -17 -5 0 5 -4 21 -10 36 -15 35 -16 101 -2 92 6 -3 10 -22 10
-41z m-824 -16 c-19 -26 -20 -20 -5 19 6 15 13 22 16 16 2 -7 -3 -23 -11 -35z
m80 -24 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1
-22z m701 -51 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-690 5
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-100 -45 c-15 -20 -24
-15 -32 14 -5 20 -2 31 12 41 16 12 19 10 24 -15 3 -15 1 -34 -4 -40z m883 7
c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z
m-80 -27 c0 -16 2 -39 5 -53 3 -14 -1 -9 -8 10 -6 19 -15 40 -19 46 -4 6 -5
14 -1 18 14 13 23 5 23 -21z m-723 -35 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m816 6 c3 -3 -2 -13 -11 -22 -16 -16 -18 -16 -34 1 -10 9 -18
19 -18 22 0 7 55 6 63 -1z m-814 -91 l-1 -48 -10 34 c-5 18 -7 40 -4 47 10 27
16 14 15 -33z m703 25 c-5 -7 -12 -24 -15 -38 -4 -22 -4 -21 -6 6 -1 31 5 44
22 44 6 0 5 -5 -1 -12z m38 -27 c0 -5 -7 -11 -14 -14 -10 -4 -13 -1 -9 9 6 15
23 19 23 5z m53 -46 c9 -25 12 -45 6 -45 -5 0 -9 5 -9 11 0 5 -3 8 -7 6 -5 -3
-9 13 -10 34 0 21 0 39 2 39 2 0 10 -20 18 -45z m-110 -59 c2 -31 0 -59 -5
-62 -4 -3 -5 3 -1 13 6 16 5 17 -12 3 -17 -14 -18 -13 -10 5 5 11 10 39 13 63
5 57 11 49 15 -22z m-693 28 c0 -14 -4 -23 -9 -20 -5 3 -7 15 -4 26 7 28 13
25 13 -6z m17 -36 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-50
-20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -15 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-90 -30 c-3 -10 -5 -4 -5 12 0 17
2 24 5 18 2 -7 2 -21 0 -30z m793 -42 c-6 -12 -15 -21 -20 -21 -5 0 -1 13 8
30 19 32 30 24 12 -9z m37 14 c0 -8 -4 -15 -9 -15 -4 0 -8 7 -8 15 0 8 4 15 8
15 5 0 9 -7 9 -15z m-684 -47 c13 -31 13 -36 1 -31 -16 6 -18 -10 -4 -37 7
-13 6 -19 -5 -24 -8 -3 -15 -12 -15 -21 0 -17 -16 -20 -25 -5 -3 6 -1 10 4 10
6 0 11 7 11 15 0 8 -9 15 -19 15 -17 0 -19 -6 -16 -42 4 -40 3 -42 -11 -23 -9
12 -11 21 -4 23 5 2 8 8 5 12 -3 5 -12 7 -20 3 -8 -3 -15 0 -15 7 0 6 4 9 9 6
5 -3 14 1 21 9 7 8 23 15 36 15 20 0 24 5 24 30 0 17 -5 30 -11 30 -5 0 -7 5
-4 10 4 6 10 8 15 5 4 -3 6 5 3 17 -8 32 2 20 20 -24z m47 -19 c0 -18 -18 -9
-23 12 -4 16 -3 18 9 8 8 -6 14 -15 14 -20z m636 -7 c-4 -3 -11 0 -16 8 -15
24 -11 33 6 15 9 -9 13 -19 10 -23z m-52 -16 c-3 -9 -10 -13 -16 -10 -5 3 -4
12 3 20 15 18 22 13 13 -10z m-64 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m-351 -26 c14 -12 -19 -1 -35 12 -18 14 -18
14 6 3 14 -6 27 -13 29 -15z m65 17 c3 -5 -6 -8 -20 -8 -19 0 -22 2 -13 8 18
11 26 11 33 0z m322 -13 c5 -7 2 -9 -8 -5 -12 4 -14 1 -11 -14 3 -11 2 -19 -3
-17 -5 1 -20 -3 -34 -10 -25 -13 -25 -13 -5 5 12 10 24 26 28 36 7 20 23 22
33 5z m51 -4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-491 -29
c-19 -14 -36 -12 -36 5 0 9 41 19 50 12 3 -2 -4 -10 -14 -17z m94 -1 c0 -8 -5
-12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m47 -3 c1 -14 -3 -18 -13 -14
-11 4 -12 9 -3 19 15 18 14 18 16 -5z m313 -1 c-6 -11 -15 -20 -20 -20 -14 0
-12 6 6 24 21 22 27 20 14 -4z m-136 -15 c-13 -10 -15 -14 -5 -15 8 0 12 -2 9
-5 -3 -3 -13 -2 -23 1 -16 6 -15 9 6 25 13 10 26 16 28 13 3 -2 -4 -11 -15
-19z m-259 -15 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0
18 -4 21 -10z m-218 -15 c12 -8 28 -12 36 -9 8 3 17 -2 20 -10 4 -9 14 -16 24
-16 14 0 15 -2 4 -12 -10 -11 -15 -9 -27 7 -8 11 -23 19 -32 18 -9 -2 -28 6
-42 17 -29 23 -15 27 17 5z m423 0 c-10 -12 -16 -13 -29 -3 -9 7 -18 14 -20
15 -2 2 10 3 29 3 28 0 31 -2 20 -15z m-434 -37 l29 -31 -37 28 c-39 28 -45
35 -30 35 5 0 21 -14 38 -32z m804 18 c0 -21 -30 -48 -46 -42 -7 3 -16 1 -19
-4 -4 -6 4 -11 17 -11 17 -1 18 -3 6 -6 -10 -2 -18 -11 -18 -18 0 -25 -31 -45
-68 -44 l-37 2 34 6 c19 3 36 11 38 18 3 9 -4 10 -29 2 -18 -5 -44 -9 -58 -8
-15 1 -8 5 19 10 27 5 53 19 70 37 14 15 38 33 54 40 15 6 27 16 27 22 0 5 2
10 5 10 3 0 5 -6 5 -14z m-460 -25 c0 -12 -24 -20 -46 -16 -11 2 -22 4 -26 4
-5 1 -8 6 -8 11 0 6 18 10 40 10 22 0 40 -4 40 -9z m-14 -53 c-7 -11 -13 -13
-17 -6 -4 6 -12 14 -19 18 -6 4 1 7 18 6 25 -1 27 -3 18 -18z m110 -19 c13
-15 14 -20 4 -15 -8 3 -23 9 -32 12 -10 4 -18 10 -18 15 0 17 27 9 46 -12z
m-220 -7 c-27 -2 -52 0 -57 5 -4 4 18 6 49 3 l57 -5 -49 -3z m324 8 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m61 -25 l34
-14 -35 3 c-19 2 -36 8 -38 15 -5 14 -1 14 39 -4z m-188 -19 c4 -10 1 -13 -8
-9 -8 3 -12 9 -9 14 7 12 11 11 17 -5z m157 -26 c0 -10 -3 -21 -8 -23 -4 -3
-7 8 -7 23 -1 16 3 26 7 24 4 -3 8 -14 8 -24z m30 9 c0 -5 -4 -9 -10 -9 -5 0
-10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-71 -3 c10 -12 8 -13 -8 -9
-12 3 -18 9 -15 14 7 12 10 11 23 -5z m11 -46 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M3469 3399 c-10 -6 -7 -9 14 -9 29 0 35 5 14 13 -8 3 -20 1 -28 -4z"/>
<path d="M3970 3191 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M4402 4155 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5701 4085 c0 -12 -6 -26 -13 -33 -6 -6 -9 -15 -5 -19 13 -13 29 14
24 43 -2 18 -5 21 -6 9z"/>
<path d="M4498 3888 c-7 -7 -20 -14 -28 -16 -8 -1 -27 -8 -43 -14 -22 -8 -25
-13 -16 -24 6 -8 24 -14 39 -14 33 0 43 -9 29 -27 -16 -19 -5 -16 49 11 59 30
76 49 67 76 -5 16 -15 20 -45 20 -21 0 -45 -6 -52 -12z m86 -13 c3 -8 1 -15
-4 -15 -6 0 -10 5 -10 11 0 7 -10 9 -25 5 -14 -4 -25 -2 -25 4 0 16 57 12 64
-5z m-16 -30 c12 -12 -10 -18 -28 -7 -24 15 -40 15 -40 2 0 -5 8 -10 17 -10
13 0 14 -3 5 -12 -9 -9 -16 -6 -30 10 -11 12 -26 22 -33 22 -11 0 -11 -2 1
-10 12 -8 11 -10 -7 -10 -27 0 -39 17 -16 23 23 5 124 0 131 -8z"/>
<path d="M4123 3883 c-7 -2 -13 -14 -12 -26 0 -22 0 -22 9 1 11 25 25 29 33 7
5 -11 12 -12 37 -4 16 6 30 14 30 17 0 8 -80 13 -97 5z"/>
<path d="M6167 3849 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M6010 3825 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M5360 3801 c0 -6 5 -11 11 -11 5 0 7 -5 4 -10 -4 -7 2 -9 18 -5 18 5
28 2 36 -12 9 -16 10 -16 11 -1 0 11 -10 20 -27 24 -16 4 -34 11 -40 17 -9 6
-13 6 -13 -2z"/>
<path d="M4387 3759 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M5996 3728 c1 -24 -1 -37 -3 -30 -6 16 -33 -3 -33 -24 0 -8 -4 -14
-10 -14 -15 0 -12 -30 3 -30 8 -1 7 -4 -3 -13 -8 -7 -16 -17 -17 -22 -1 -6 -5
-19 -8 -30 -4 -11 9 2 29 29 26 35 34 56 30 70 -6 18 -5 19 8 8 13 -10 17 -10
22 3 3 9 10 13 16 10 6 -3 7 1 4 9 -3 9 -10 16 -15 16 -14 0 -10 27 7 44 14
15 13 16 -8 16 -22 0 -24 -4 -22 -42z"/>
<path d="M3100 3744 c0 -8 -4 -13 -9 -10 -15 10 -21 -23 -20 -117 1 -51 4 -83
6 -72 3 11 9 22 13 25 7 5 21 190 14 190 -2 0 -4 -7 -4 -16z m-13 -86 c-2 -13
-4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z"/>
<path d="M4362 3660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4057 3663 c-4 -3 -7 -16 -6 -27 0 -19 1 -19 10 3 10 24 8 36 -4 24z"/>
<path d="M4852 3627 c-7 -20 -25 -46 -39 -57 -28 -21 -24 -26 8 -10 23 13 51
65 47 87 -2 9 -9 0 -16 -20z"/>
<path d="M5101 3598 c-1 -9 -4 -24 -8 -34 -4 -12 -3 -15 5 -10 7 4 12 2 12 -5
0 -7 2 -10 4 -8 3 2 1 20 -4 39 -4 19 -8 27 -9 18z"/>
<path d="M6212 3580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4180 3487 c4 -41 5 -41 8 -8 2 18 0 37 -5 42 -5 5 -6 -11 -3 -34z"/>
<path d="M4178 3411 c-5 -18 -8 -43 -8 -55 1 -19 2 -19 11 -3 6 9 9 34 7 55
l-3 37 -7 -34z"/>
<path d="M5812 3300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4600 3260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4595 3230 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
